import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ReportsService } from '../../../_services/reports.service';
import { ClientsService } from '../../../_services/clients.service';
import { ApiService } from '../../../_interceptors/api.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
var ɵ0 = APP_DATE_FORMATS;
var ReportsQuestionnaireComponent = /** @class */ (function () {
    function ReportsQuestionnaireComponent(reportsService, authenticationService, apiService, clientsService, formsService, activatedRoute, formBuilder, previousUrlService, pageTitleService) {
        this.reportsService = reportsService;
        this.authenticationService = authenticationService;
        this.apiService = apiService;
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.report_id = null;
        this.report_type = null;
        this.dataLoaded = false;
        this.missingData = [];
        this.reportEditable = false;
        this.report = null;
        this.client = null;
        this.questionnaire = [];
        this.reportQuestionnaireForm = this.formBuilder.group({
            questions: this.formBuilder.group([])
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
        this._authenticationServiceSubscription = null;
    }
    ReportsQuestionnaireComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.missingData.length) {
                _this.missingData = [];
                _this.loadData();
            }
        });
        this.formsService.form = this.reportQuestionnaireForm;
        this._errorsSubscription = this.reportsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.report_type = params['report_type'];
            _this.report_id = parseInt(params['report_id'], 10);
            _this.reportsService.getReport(_this.report_type, _this.report_id).then(function (report) {
                _this._authenticationServiceSubscription = _this.authenticationService.user.first().subscribe(function (user) {
                    if (typeof report !== 'undefined' && typeof user !== 'undefined') {
                        _this.pageTitleService.setTitle(report.report_nr + ' | Rapportage inspectieschema | ' + environment.webappName);
                        _this.pageTitleService.setPageTitle(report.report_nr);
                        if (report.status !== 'closed') {
                            if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
                                if (report.edit_lock === true && (report.edit_lock_user_id === '' ||
                                    report.edit_lock_user_id === null ||
                                    report.edit_lock_user_id === 0 ||
                                    report.edit_lock_user_id === user.id ||
                                    user.type === 'kvvm-admin')) {
                                    _this.reportEditable = true;
                                }
                            }
                        }
                    }
                });
            });
            _this.previousUrlService.setPreviousUrlIfNull('/reports/' + _this.report_type + '/' + _this.report_id + '/view');
            _this.loadData();
        });
    };
    ReportsQuestionnaireComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
        if (this._authenticationServiceSubscription !== null) {
            this._authenticationServiceSubscription.unsubscribe();
        }
    };
    ReportsQuestionnaireComponent.prototype.loadData = function () {
        var _this = this;
        this.reportsService.getReport(this.report_type, this.report_id).then(function (report) {
            if (typeof report !== 'undefined') {
                _this.report = report;
                _this.clientsService.getClient(report.client_id).then(function (client) {
                    _this.client = client;
                });
                _this.reportsService.getWarehouseQuestionnaires(_this.report_type).then(function (questionnaire) {
                    if (typeof questionnaire !== 'undefined') {
                        if (typeof questionnaire.data !== 'undefined') {
                            questionnaire.data.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            _this.reportsService.getWarehouseQuestionnaireAnswer(_this.report_type, _this.report_id).then(function (answers) {
                                questionnaire.data.forEach(function (question_group) {
                                    var control = _this.reportQuestionnaireForm.get('questions'), answer = (typeof answers !== 'undefined' ? (typeof answers.questions !== 'undefined' ? (typeof answers.questions['[' + question_group.id + '][status]'] !== 'undefined' ? answers.questions['[' + question_group.id + '][status]'] : null) : null) : null);
                                    control.addControl('[' + question_group.id + '][status]', new FormControl(answer));
                                    question_group['status'] = answer;
                                    if (typeof question_group.questions !== 'undefined') {
                                        question_group.questions.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                                        if (typeof question_group.questions !== 'undefined') {
                                            question_group.questions.forEach(function (question) {
                                                var answer2 = (typeof answers !== 'undefined' ? (typeof answers.questions !== 'undefined' ? (typeof answers.questions['[' + question_group.id + '][' + question.id + ']'] !== 'undefined' ? answers.questions['[' + question_group.id + '][' + question.id + ']'] : null) : null) : 368);
                                                control.addControl('[' + question_group.id + '][' + question.id + ']', new FormControl(answer2));
                                                question['result'] = answer2;
                                            });
                                        }
                                    }
                                    _this.questionnaire.push(question_group);
                                });
                            });
                        }
                        else {
                            _this.missingData.push('Inspectieschema');
                        }
                    }
                    else {
                        _this.missingData.push('Inspectieschema');
                    }
                });
                _this.dataLoaded = true;
            }
            else {
                _this.missingData.push('Rapportage');
            }
        });
    };
    return ReportsQuestionnaireComponent;
}());
export { ReportsQuestionnaireComponent };
export { ɵ0 };
