import { ReplaySubject } from 'rxjs/ReplaySubject';
import { MediaObserver } from '@angular/flex-layout';
var MediaReplayService = /** @class */ (function () {
    function MediaReplayService(media) {
        var _this = this;
        this._media$ = new ReplaySubject(1);
        media.media$
            .subscribe(function (res) { return _this._media$.next(res); }, function (err) { return _this._media$.error(err); }, function () { return _this._media$.complete(); });
    }
    Object.defineProperty(MediaReplayService.prototype, "media$", {
        get: function () {
            return this._media$.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    return MediaReplayService;
}());
export { MediaReplayService };
