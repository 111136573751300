import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ClientsService } from '../../../_services/clients.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import * as moment from 'moment';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
var ɵ0 = APP_DATE_FORMATS;
var ClientsNextInspectionNotificationComponent = /** @class */ (function () {
    function ClientsNextInspectionNotificationComponent(clientsService, formsService, activatedRoute, formBuilder, previousUrlService, pageTitleService) {
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.client = null;
        this.report = null;
        this.client_id = null;
        this.nextInspectionNotification = null;
        this.dataLoaded = false;
        this.isOffline = false;
        this.nextInspectionNotificationForm = this.formBuilder.group({
            next_inspection_date: ['', Validators.required],
            next_inspection_date_input: [''],
            contact_company: [true],
            contact_persons_ids: this.formBuilder.array([]),
            custom_contacts: ['']
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    ClientsNextInspectionNotificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formsService.form = this.nextInspectionNotificationForm;
        this._errorsSubscription = this.clientsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.client_id = parseInt(params['client_id'], 10);
            _this.clientsService.getClient(_this.client_id).then(function (client) {
                if (typeof client !== 'undefined') {
                    _this.client = client;
                    _this.pageTitleService.setTitle(client.company_name + ' | Verstuur volgende inspectie notificatie | ' + environment.webappName);
                    _this.pageTitleService.setPageTitle(client.company_name);
                }
            });
            _this.previousUrlService.setPreviousUrlIfNull('clients/' + _this.client_id + '/view');
            _this.clientsService.getNextInspectionNotificationDetails(_this.client_id).then(function (details) {
                if (typeof details !== 'undefined') {
                    _this.nextInspectionNotificationForm.get('next_inspection_date_input').setValue(new Date(details.next_inspection_date));
                    if (details.id !== 0 && details.created !== 0 && details.updated !== 0 && details.report_nr !== null && details.inspector !== null && details.status !== null) {
                        _this.report = {
                            client_id: details.client_id,
                            company_name: details.company_name,
                            id: details.id,
                            inspector: details.inspector,
                            type: details.type,
                            type_text: details.type,
                            report_nr: details.report_nr,
                            status: details.status,
                            created: details.created,
                            updated: details.updated
                        };
                    }
                    _this.nextInspectionNotification = details;
                    if (typeof details.contact_persons_ids !== 'undefined') {
                        details.contact_persons_ids.forEach(function (contact_person) {
                            var control = _this.nextInspectionNotificationForm.controls.contact_persons_ids;
                            control.push(new FormControl({ value: contact_person.id, disabled: !contact_person.email }));
                        });
                    }
                    _this.formsService.populateForm(details);
                    _this.nextInspectionNotificationForm.get('next_inspection_date').setValue(moment(details.next_inspection_date).format('DD-MM-YYYY'));
                    var el = _this.nextInspectionNotificationForm.get('contact_company');
                    if (details.email === '') {
                        el.setValue(false);
                        el.disable();
                    }
                    else {
                        el.setValue(true);
                        el.enable();
                    }
                    _this.dataLoaded = true;
                }
                else {
                    _this.isOffline = true;
                }
            });
        });
    };
    ClientsNextInspectionNotificationComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    };
    ClientsNextInspectionNotificationComponent.prototype.fixAngularScrewUpAndInsertActualValue = function (event, index) {
        if (event.checked) {
            this.nextInspectionNotificationForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
        }
    };
    ClientsNextInspectionNotificationComponent.prototype.updateNextInspectionDate = function ($event) {
        this.nextInspectionNotificationForm.get('next_inspection_date').setValue(moment($event.value).format('DD-MM-YYYY'));
    };
    return ClientsNextInspectionNotificationComponent;
}());
export { ClientsNextInspectionNotificationComponent };
export { ɵ0 };
