import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../../../_services/general.service';
import { FormsService } from '../../../../_services/forms.service';
import { InspectorsService } from '../../../../_services/inspectors.service';
import * as moment from 'moment';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../../_pipes/angular2-material-datepicker-custom-date';
import { ApiService } from '../../../../_interceptors/api.service';
var ɵ0 = APP_DATE_FORMATS;
var InspectorsCalibrationsEditComponent = /** @class */ (function () {
    function InspectorsCalibrationsEditComponent(generalService, inspectorsService, formsService, formBuilder, activatedRoute, apiService, previousUrlService) {
        this.generalService = generalService;
        this.inspectorsService = inspectorsService;
        this.formsService = formsService;
        this.formBuilder = formBuilder;
        this.activatedRoute = activatedRoute;
        this.apiService = apiService;
        this.previousUrlService = previousUrlService;
        this.routerTransition = true;
        this.calibration_id = null;
        this.dataLoaded = false;
        this.missingData = [];
        this.inspectorCalibrationForm = this.formBuilder.group({
            device_id: ['', [Validators.required, Validators.maxLength(100)]],
            device_brand: ['', [Validators.required, Validators.maxLength(100)]],
            device_description: ['', [Validators.required, Validators.maxLength(100)]],
            date_calibrated: ['', Validators.required],
            date_calibrated_input: [''],
            next_calibration_date: ['', Validators.required],
            next_calibration_date_input: [''],
            certificate_number: ['', Validators.required]
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    InspectorsCalibrationsEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.missingData.length > 0) {
                _this.missingData = [];
                _this.loadData();
            }
        });
        this.formsService.form = this.inspectorCalibrationForm;
        this.previousUrlService.setPreviousUrlIfNull('/inspectors/calibrations');
        this._errorsSubscription = this.inspectorsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.calibration_id = parseInt(params['calibration_id'], 10);
            _this.loadData();
        });
    };
    InspectorsCalibrationsEditComponent.prototype.ngOnDestroy = function () {
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
    };
    InspectorsCalibrationsEditComponent.prototype.updateInspectorDateCalibrated = function ($event) {
        this.inspectorCalibrationForm.get('date_calibrated').setValue(moment($event.value).format('DD-MM-YYYY'));
    };
    InspectorsCalibrationsEditComponent.prototype.updateInspectorNextCalibrationDate = function ($event) {
        this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment($event.value).format('DD-MM-YYYY'));
    };
    InspectorsCalibrationsEditComponent.prototype.loadData = function () {
        var _this = this;
        this.inspectorsService.getInspectorCalibration(this.calibration_id).then(function (details) {
            if (typeof details !== 'undefined') {
                _this.inspectorCalibrationForm.get('date_calibrated').setValue(moment(details.date_calibrated).format('DD-MM-YYYY'));
                _this.inspectorCalibrationForm.get('date_calibrated_input').setValue(new Date(details.date_calibrated));
                _this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment(details.next_calibration_date).format('DD-MM-YYYY'));
                _this.inspectorCalibrationForm.get('next_calibration_date_input').setValue(new Date(details.next_calibration_date));
                _this.formsService.populateForm(details);
                _this.dataLoaded = true;
            }
            else {
                _this.missingData.push('Kalibratie');
            }
        });
    };
    return InspectorsCalibrationsEditComponent;
}());
export { InspectorsCalibrationsEditComponent };
export { ɵ0 };
