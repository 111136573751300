var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/Rx';
import { LoadingOverlayService } from './loading-overlay.service';
import { ApiService } from '../_interceptors/api.service';
import { SnackbarService } from './snackbar.service';
import { PreviousUrlService } from './previous-url.service';
import { MatDialog } from '@angular/material/dialog';
import { SimpleModalDialogComponent } from '../core/simple-modal/simple-modal.component';
import { IndexedDBService } from './indexeddb.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
var ClientsService = /** @class */ (function () {
    function ClientsService(apiService, dialogService, overlayService, snackbarService, previousUrlService, indexedDBService, router) {
        this.apiService = apiService;
        this.dialogService = dialogService;
        this.overlayService = overlayService;
        this.snackbarService = snackbarService;
        this.previousUrlService = previousUrlService;
        this.indexedDBService = indexedDBService;
        this.router = router;
        this.errors = new BehaviorSubject(null);
        this.clientDeleted = new BehaviorSubject(null);
        this.startIndex = 0;
        this.endIndex = -1;
        this.searchString = null;
        this.debug = !environment.production;
    }
    ClientsService.prototype.formData = function (formData) {
        this.startIndex = 0;
        this.endIndex = -1;
        this.searchString = null;
        if (formData !== null) {
            if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                this.startIndex = parseInt(formData['start'], 10);
                this.endIndex = parseInt(formData['length'], 10);
                if (this.endIndex !== -1) {
                    this.endIndex += this.startIndex;
                }
            }
            if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                    this.searchString = formData['columns[0][search][value]'].toLowerCase();
                }
            }
        }
    };
    ClientsService.prototype.getAttachments = function (client, formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.formData(formData);
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableClientsAttachments)
                        .then(function (attachments) {
                        if (typeof attachments !== 'undefined') {
                            resolve(_this.processAttachments(client, attachments));
                        }
                        else {
                            resolve(_this.getAttachmentsFromServer(client));
                        }
                    }, function () { return resolve(_this.getAttachmentsFromServer(client)); })
                        .catch(function () { return resolve(_this.getAttachmentsFromServer(client)); });
                }
            });
        });
    };
    ClientsService.prototype.getAttachmentsFromServer = function (client) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClientsAttachments().then(function (attachments) {
                if (typeof attachments !== 'undefined') {
                    resolve(_this.processAttachments(client, attachments));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processAttachments = function (client, attachments) {
        var _this = this;
        return new Promise(function (resolve) {
            console.log(attachments);
            var collectionData = attachments.filter(function (attachment) {
                return (attachment.client_id === client.id ||
                    attachment.client_id === client.head_office_id) &&
                    (typeof attachment.indexedDBMethod === 'undefined' || typeof attachment.indexedDBMethod !== 'undefined' && attachment.indexedDBMethod !== '3-delete');
            });
            console.log(collectionData);
            if (_this.searchString !== null) {
                collectionData = collectionData.filter(function (attachment) { return attachment.title.toLowerCase().indexOf(_this.searchString) !== -1; });
            }
            collectionData.sort(function (a, b) { return a.created > b.created ? 1 : -1; });
            resolve({
                recordsTotal: collectionData.length,
                data: _this.endIndex !== -1 ? collectionData.slice(_this.startIndex, _this.endIndex) : collectionData.slice()
            });
        });
    };
    ClientsService.prototype.getAttachment = function (client_id, attachment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableClientsAttachments, attachment_id)
                        .then(function (attachment) {
                        if (typeof attachment !== 'undefined') {
                            resolve(attachment);
                        }
                        else {
                            resolve(_this.getAttachmentFromServer(client_id, attachment_id));
                        }
                    }, function () { return resolve(_this.getAttachmentFromServer(client_id, attachment_id)); })
                        .catch(function () { return resolve(_this.getAttachmentFromServer(client_id, attachment_id)); });
                }
            });
        });
    };
    ClientsService.prototype.getAttachmentFromServer = function (client_id, attachment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClientsAttachments().then(function (attachments) {
                if (typeof attachments !== 'undefined') {
                    resolve(_this.processAttachment(client_id, attachment_id, attachments));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processAttachment = function (client_id, attachment_id, attachments) {
        return new Promise(function (resolve) {
            var resultsData = attachments.filter(function (attachment) {
                return (attachment.client_id === client_id || attachment.head_office_id === client_id) &&
                    attachment.id === attachment_id;
            });
            if (resultsData.length) {
                resolve(resultsData[0]);
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.uploadNewAttachment = function (client_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/attachments', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Bijlage opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableClientsAttachments, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            formData['indexedDBMethod'] = '1-create';
            formData['client_id'] = client_id;
            formData['created'] = moment().valueOf();
            formData['id'] = moment().valueOf();
            var newFormData = {
                id: formData['id'],
                client_id: client_id,
                created: formData['created'],
                description: formData['description'],
                filename: formData.filename._files[0].name,
                original_filename: formData.filename._files[0].name,
                title: formData['title'],
                append_ladder: formData['append_ladder'],
                append_warehouse: formData['append_warehouse'],
                append_tent: formData['append_tent'],
            };
            this.indexedDBService.database.add(this.indexedDBService.tableClientsAttachments, newFormData).then(function () {
                _this.snackbarService.success('Bijlage opgeslagen!');
                _this.indexedDBService.database.add(_this.indexedDBService.tableClientsAttachmentsPush, formData).then(function () {
                }, function (error) {
                    console.log(error);
                    throw new Error(error);
                });
                if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                    _this.previousUrlService.goToPreviousUrl();
                }
                else {
                    _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                    });
                }
            }, function (error) {
                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.updateAttachment = function (client_id, attachment_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/attachments/' + attachment_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Bijlage opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableClientsAttachments, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableClientsAttachments, 'id', attachment_id).then(function (attachment) {
                var newAttachment = __assign({}, attachment, formData);
                _this.indexedDBService.database.update(_this.indexedDBService.tableClientsAttachments, newAttachment).then(function () {
                    _this.snackbarService.success('Bijlage opgeslagen!');
                    newAttachment['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableClientsAttachmentsPush, newAttachment).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            }, function (error) {
                console.log(error);
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.deleteAttachment = function (client_id, attachment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze bijlage wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('clients/clients/' + client_id + '/attachments/' + attachment_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Bijlage verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsAttachments, attachment_id).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsAttachments, attachment_id).then(function () {
                            _this.snackbarService.success('Bijlage verwijderd.');
                            var formData = {
                                'client_id': client_id,
                                'id': attachment_id,
                                'indexedDBMethod': '3-delete'
                            };
                            _this.indexedDBService.database.add(_this.indexedDBService.tableClientsAttachmentsPush, formData).then(function () {
                            }, function (error) {
                                console.log(error);
                                throw new Error(error);
                            });
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ClientsService.prototype.downloadFile = function (url, filename) {
        if (this.apiService.isOnline) {
            if (typeof url !== 'undefined') {
                this.apiService.download(url, filename);
            }
            else {
                this.snackbarService.error('Ongeldige link, kan niet worden gedownload.');
            }
        }
        else {
            this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
        }
    };
    ClientsService.prototype.getClients = function (formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event === true) {
                    _this.formData(formData);
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableClients)
                        .then(function (clients) {
                        if (typeof clients !== 'undefined') {
                            resolve(_this.processClients(clients));
                        }
                        else {
                            resolve(_this.getClientsFromServer());
                        }
                    }, function () { return resolve(_this.getClientsFromServer()); })
                        .catch(function () { return resolve(_this.getClientsFromServer()); });
                }
            });
        });
    };
    ClientsService.prototype.getClientsFromServer = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClients().then(function (clients) {
                if (typeof clients !== 'undefined') {
                    resolve(_this.processClients(clients));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processClients = function (clients) {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = clients.filter(function (client) {
                return typeof client.indexedDBMethod === 'undefined' ||
                    typeof client.indexedDBMethod !== 'undefined' && client.indexedDBMethod !== '3-delete';
            });
            if (_this.searchString !== null) {
                collectionData = collectionData.filter(function (client) { return client.company_name.toLowerCase().indexOf(_this.searchString) !== -1; });
            }
            collectionData.sort(function (a, b) { return a.company_name.toLowerCase() > b.company_name.toLowerCase() ? 1 : -1; });
            resolve({
                recordsTotal: collectionData.length,
                data: _this.endIndex !== -1 ? collectionData.slice(_this.startIndex, _this.endIndex) : collectionData.slice()
            });
        });
    };
    ClientsService.prototype.getClient = function (client_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event === true) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableClients, client_id)
                        .then(function (client) {
                        if (typeof client !== 'undefined') {
                            resolve(client);
                        }
                        else {
                            resolve(_this.getClientFromServer(client_id));
                        }
                    }, function () { return resolve(_this.getClientFromServer(client_id)); })
                        .catch(function () { return resolve(_this.getClientFromServer(client_id)); });
                }
            });
        });
    };
    ClientsService.prototype.getClientFromServer = function (client_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClients().then(function (clients) {
                if (typeof clients !== 'undefined') {
                    resolve(_this.processClient(client_id, clients));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processClient = function (client_id, clients) {
        return new Promise(function (resolve) {
            var resultsData = clients.filter(function (client) { return client.id === client_id; });
            if (resultsData.length) {
                resolve(resultsData[0]);
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.createClient = function (formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Klant opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableClients, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                        _this.router.navigate(['/clients/' + data.data.id + '/view']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/clients']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            formData['id'] = moment().valueOf();
            formData['last_inspection_date'] = null;
            formData['next_inspection_date'] = null;
            formData['next_inspection_email_sent'] = null;
            delete formData['country_id_filterString'];
            var pushClientData_1 = formData;
            pushClientData_1['indexedDBMethod'] = '1-create';
            pushClientData_1['date_created'] = moment().valueOf();
            this.indexedDBService.database.add(this.indexedDBService.tableClients, formData).then(function (id) {
                _this.snackbarService.success('Klant opgeslagen!');
                _this.indexedDBService.database.add(_this.indexedDBService.tableClientsPush, pushClientData_1).then(function () {
                }, function (error) {
                    console.log(error);
                    throw new Error(error);
                });
                _this.router.navigate(['/clients/' + id + '/view']).then(function () {
                });
            }, function (error) {
                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.updateClient = function (client_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Klant opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableClients, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableClients, 'id', client_id).then(function (client) {
                var newClient = __assign({}, client, formData);
                _this.indexedDBService.database.update(_this.indexedDBService.tableClients, newClient).then(function () {
                    _this.snackbarService.success('Klant opgeslagen!');
                    newClient['indexedDBMethod'] = '2-update';
                    newClient['date_updated'] = moment().valueOf();
                    _this.indexedDBService.database.add(_this.indexedDBService.tableClientsPush, newClient).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            }, function (error) {
                console.log(error);
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.deleteClient = function (client_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze klant wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        _this.apiService.delete('clients/clients/' + client_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Klant verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableClients, client_id).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    throw new Error(error);
                                });
                                _this.cleanupAfterClientDeletion(client_id);
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableClients, client_id).then(function () {
                            _this.snackbarService.success('Klant verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableClientsPush, {
                                id: client_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                            }, function (error) {
                                console.log(error);
                                throw new Error(error);
                            });
                            _this.cleanupAfterClientDeletion(client_id);
                            resolve(true);
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            resolve(false);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ClientsService.prototype.cleanupAfterClientDeletion = function (client_id) {
        var _this = this;
        this.indexedDBService.database.openCursorWithIndex(this.indexedDBService.tableClientsAttachments, 'client_id', function (event) {
            var cursor = event.target.result;
            if (cursor) {
                if (cursor.value.client_id === client_id) {
                    _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsAttachments, cursor.value.id).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                }
                cursor.continue();
            }
        }).then(function () {
        }, function (error) {
            console.log(error);
            throw new Error(error);
        });
        this.indexedDBService.database.openCursorWithIndex(this.indexedDBService.tableClientsContactPersons, 'client_id', function (event) {
            var cursor = event.target.result;
            if (cursor) {
                if (cursor.value.client_id === client_id) {
                    _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsContactPersons, cursor.value.id).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                }
                cursor.continue();
            }
        }).then(function () {
        }, function (error) {
            console.log(error);
            throw new Error(error);
        });
        this.clientDeleted.next(client_id);
    };
    ClientsService.prototype.getContactPersons = function (client_id, formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.formData(formData);
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableClientsContactPersons)
                        .then(function (contactPersons) {
                        if (typeof contactPersons !== 'undefined') {
                            resolve(_this.processContactPersons(client_id, contactPersons));
                        }
                        else {
                            resolve(_this.getContactPersonsFromServer(client_id));
                        }
                    }, function () { return resolve(_this.getContactPersonsFromServer(client_id)); })
                        .catch(function () { return resolve(_this.getContactPersonsFromServer(client_id)); });
                }
            });
        });
    };
    ClientsService.prototype.getContactPersonsFromServer = function (client_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClientsContactPersons().then(function (contactPersons) {
                if (typeof contactPersons !== 'undefined') {
                    resolve(_this.processContactPersons(client_id, contactPersons));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(_this.getContactPersonsFromServer(client_id)); });
        });
    };
    ClientsService.prototype.processContactPersons = function (client_id, contactPersons) {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = contactPersons.filter(function (contactPerson) {
                return contactPerson.client_id === client_id &&
                    (typeof contactPerson.indexedDBMethod === 'undefined' || typeof contactPerson.indexedDBMethod !== 'undefined' && contactPerson.indexedDBMethod !== '3-delete');
            });
            if (_this.searchString !== null) {
                collectionData = collectionData.filter(function (contactPerson) { return contactPerson.firstname.toLowerCase().indexOf(_this.searchString) !== -1; });
            }
            collectionData.sort(function (a, b) { return a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1; });
            resolve({
                recordsTotal: collectionData.length,
                data: _this.endIndex !== -1 ? collectionData.slice(_this.startIndex, _this.endIndex) : collectionData.slice()
            });
        });
    };
    ClientsService.prototype.getContactPerson = function (client_id, contact_person_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableClientsContactPersons, contact_person_id)
                        .then(function (contactPerson) {
                        if (typeof contactPerson !== 'undefined') {
                            resolve(contactPerson);
                        }
                        else {
                            resolve(_this.getContactPersonFromServer(client_id, contact_person_id));
                        }
                    }, function () { return resolve(_this.getContactPersonFromServer(client_id, contact_person_id)); })
                        .catch(function () { return resolve(_this.getContactPersonFromServer(client_id, contact_person_id)); });
                }
            });
        });
    };
    ClientsService.prototype.getContactPersonFromServer = function (client_id, contact_person_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClientsContactPersons().then(function (contactPersons) {
                if (typeof contactPersons !== 'undefined') {
                    resolve(_this.processContactPerson(client_id, contact_person_id, contactPersons));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processContactPerson = function (client_id, contact_person_id, contact_persons) {
        return new Promise(function (resolve) {
            var resultsData = contact_persons.filter(function (contactPerson) { return contactPerson.client_id === client_id && contactPerson.id === contact_person_id; });
            if (resultsData.length) {
                resolve(resultsData[0]);
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.createContactPerson = function (client_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/contacts-persons', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Contactpersoon opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableClientsContactPersons, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            formData['id'] = moment().valueOf();
            formData['client_id'] = client_id;
            this.indexedDBService.database.add(this.indexedDBService.tableClientsContactPersons, formData).then(function () {
                _this.snackbarService.success('Contactpersoon opgeslagen!');
                formData['indexedDBMethod'] = '1-create';
                formData['date_created'] = moment().valueOf();
                _this.indexedDBService.database.add(_this.indexedDBService.tableClientsContactPersonsPush, formData).then(function () {
                }, function (error) {
                    console.log(error);
                    throw new Error(error);
                });
                _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                });
            }, function (error) {
                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.updateContactPerson = function (client_id, contact_person_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/contacts-persons/' + contact_person_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Contactpersoon opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableClientsContactPersons, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableClientsContactPersons, 'id', contact_person_id).then(function (contactPerson) {
                var newContactPerson = __assign({}, contactPerson, formData);
                _this.indexedDBService.database.update(_this.indexedDBService.tableClientsContactPersons, newContactPerson).then(function () {
                    _this.snackbarService.success('Contactpersoon opgeslagen!');
                    newContactPerson['indexedDBMethod'] = '2-update';
                    newContactPerson['date_updated'] = moment().valueOf();
                    _this.indexedDBService.database.add(_this.indexedDBService.tableClientsContactPersonsPush, newContactPerson).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            }, function (error) {
                console.log(error);
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.deleteContactPerson = function (client_id, contact_person_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze contactpersoon wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        _this.apiService.delete('clients/clients/' + client_id + '/contacts-persons/' + contact_person_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Contactpersoon verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsContactPersons, contact_person_id).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsContactPersons, contact_person_id).then(function () {
                            _this.snackbarService.success('Contactpersoon verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableClientsContactPersonsPush, {
                                id: contact_person_id,
                                client_id: client_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                            }, function (error) {
                                console.log(error);
                                throw new Error(error);
                            });
                            resolve(true);
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            resolve(false);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ClientsService.prototype.uploadNewBuildingMap = function (client_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/building-maps', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Plattegrond opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableClientsBuildingsMaps, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            formData['indexedDBMethod'] = '1-create';
            formData['client_id'] = client_id;
            formData['created'] = moment().valueOf();
            formData['id'] = moment().valueOf();
            var newFormData = {
                id: formData['id'],
                client_id: client_id,
                created: formData['created'],
                building_name: formData['building_name'],
                filename: formData.filename._files[0].name,
            };
            this.indexedDBService.database.add(this.indexedDBService.tableClientsBuildingsMaps, newFormData).then(function () {
                _this.snackbarService.success('Plattegrond opgeslagen!');
                _this.indexedDBService.database.add(_this.indexedDBService.tableClientsBuildingsMapsPush, formData).then(function () {
                }, function (error) {
                    console.log(error);
                    throw new Error(error);
                });
                if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                    _this.previousUrlService.goToPreviousUrl();
                }
                else {
                    _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                    });
                }
            }, function (error) {
                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.updateBuildingMap = function (client_id, building_map_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/building-maps/' + building_map_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Plattegrond opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableClientsBuildingsMaps, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableClientsBuildingsMaps, 'id', building_map_id).then(function (buildingMap) {
                var newBuildingMap = __assign({}, buildingMap, formData);
                _this.indexedDBService.database.update(_this.indexedDBService.tableClientsBuildingsMaps, newBuildingMap).then(function () {
                    _this.snackbarService.success('Plattegrond opgeslagen!');
                    newBuildingMap['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableClientsBuildingsMapsPush, newBuildingMap).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    }
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            }, function (error) {
                console.log(error);
                throw new Error(error);
            });
        }
    };
    ClientsService.prototype.deleteBuildingMap = function (client_id, building_map_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze plattegrond wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('clients/clients/' + client_id + '/building-maps/' + building_map_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Plattegrond verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsBuildingsMaps, building_map_id).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableClientsBuildingsMaps, building_map_id).then(function () {
                            _this.snackbarService.success('Plattegrond verwijderd.');
                            var formData = {
                                'client_id': client_id,
                                'id': building_map_id,
                                'indexedDBMethod': '3-delete'
                            };
                            _this.indexedDBService.database.add(_this.indexedDBService.tableClientsBuildingsMapsPush, formData).then(function () {
                            }, function (error) {
                                console.log(error);
                                throw new Error(error);
                            });
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ClientsService.prototype.getBuildingMaps = function (client_id, formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.formData(formData);
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableClientsBuildingsMaps)
                        .then(function (buildingMaps) {
                        if (typeof buildingMaps !== 'undefined') {
                            resolve(_this.processBuildingMaps(client_id, buildingMaps));
                        }
                        else {
                            resolve(_this.getBuildingMapsFromServer(client_id));
                        }
                    }, function () { return resolve(_this.getBuildingMapsFromServer(client_id)); })
                        .catch(function () { return resolve(_this.getBuildingMapsFromServer(client_id)); });
                }
            });
        });
    };
    ClientsService.prototype.getBuildingMapsFromServer = function (client_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClientsBuildingMaps().then(function (buildingMaps) {
                if (typeof buildingMaps !== 'undefined') {
                    resolve(_this.processBuildingMaps(client_id, buildingMaps));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processBuildingMaps = function (client_id, buildingMaps) {
        var _this = this;
        return new Promise(function (resolve) {
            var collectionData = buildingMaps.filter(function (buildingMap) {
                return buildingMap.client_id === client_id &&
                    (typeof buildingMap.indexedDBMethod === 'undefined' || typeof buildingMap.indexedDBMethod !== 'undefined' && buildingMap.indexedDBMethod !== '3-delete');
            });
            if (_this.searchString !== null) {
                collectionData = collectionData.filter(function (buildingMap) { return buildingMap.building_name.toLowerCase().indexOf(_this.searchString) !== -1; });
            }
            collectionData.sort(function (a, b) { return a.created > b.created ? 1 : -1; });
            resolve({
                recordsTotal: collectionData.length,
                data: _this.endIndex !== -1 ? collectionData.slice(_this.startIndex, _this.endIndex) : collectionData.slice()
            });
        });
    };
    ClientsService.prototype.getBuildingMap = function (client_id, building_map_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableClientsBuildingsMaps, building_map_id)
                        .then(function (buildingMap) {
                        if (typeof buildingMap !== 'undefined') {
                            resolve(buildingMap);
                        }
                        else {
                            resolve(_this.getBuildingMapFromServer(client_id, building_map_id));
                        }
                    }, function () { return resolve(_this.getBuildingMapFromServer(client_id, building_map_id)); })
                        .catch(function () { return resolve(_this.getBuildingMapFromServer(client_id, building_map_id)); });
                }
            });
        });
    };
    ClientsService.prototype.getBuildingMapFromServer = function (client_id, building_map_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.syncClientsBuildingMaps().then(function (buildingMaps) {
                if (typeof buildingMaps !== 'undefined') {
                    resolve(_this.processBuildingMap(client_id, building_map_id, buildingMaps));
                }
                else {
                    resolve(undefined);
                }
            }).catch(function () { return resolve(undefined); });
        });
    };
    ClientsService.prototype.processBuildingMap = function (client_id, building_map_id, buildingMaps) {
        return new Promise(function (resolve) {
            var resultsData = buildingMaps.filter(function (buildingMap) { return buildingMap.client_id === client_id && buildingMap.id === building_map_id; });
            if (resultsData.length) {
                resolve(resultsData[0]);
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.getNextInspectionNotificationDetails = function (client_id) {
        var _this = this;
        if (this.apiService.isOnline) {
            return this.apiService.get('clients/clients/' + client_id + '/next-inspection-notification').then(function (data) {
                if (data.success === true) {
                    if (typeof data.data !== 'undefined') {
                        return data.data;
                    }
                    else {
                        _this.overlayService.showError();
                    }
                }
                else {
                    _this.overlayService.showError();
                }
            });
        }
        else {
            this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
            return new Promise(function (resolve) { return resolve(undefined); });
        }
    };
    ClientsService.prototype.sendNextInspectionNotificationDetails = function (client_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/next-inspection-notification', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Volgende inspectie notificatie verstuurd. ');
                    _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableClients, 'id', client_id).then(function (client) {
                        if (typeof client !== 'undefined') {
                            client['next_inspection_date'] = moment(formData['next_inspection_date'], 'DD-MM-YYYY').valueOf();
                            _this.indexedDBService.database.update(_this.indexedDBService.tableClients, client);
                        }
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
            return new Promise(function (resolve) { return resolve(undefined); });
        }
    };
    ClientsService.prototype.getChangeNextInspectionDate = function (client_id) {
        var _this = this;
        if (this.apiService.isOnline) {
            return this.apiService.get('clients/clients/' + client_id + '/change-next-inspection-date').then(function (data) {
                if (data.success === true) {
                    if (typeof data.data !== 'undefined') {
                        return data.data;
                    }
                    else {
                        _this.overlayService.showError();
                    }
                }
                else {
                    _this.overlayService.showError();
                }
            });
        }
        else {
            this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
            return new Promise(function (resolve) { return resolve(undefined); });
        }
    };
    ClientsService.prototype.changeNextInspectionDate = function (client_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('clients/clients/' + client_id + '/change-next-inspection-date', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Inspectie datum verstuurd.');
                    _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableClients, 'id', client_id).then(function (client) {
                        if (typeof client !== 'undefined') {
                            client['next_inspection_date'] = moment(formData['next_inspection_date'], 'DD-MM-YYYY').valueOf();
                            _this.indexedDBService.database.update(_this.indexedDBService.tableClients, client);
                        }
                        _this.router.navigate(['/clients/' + client_id + '/view']).then(function () {
                        });
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
            return new Promise(function (resolve) { return resolve(undefined); });
        }
    };
    ClientsService.prototype.syncClients = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('clients/clients/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('clients/clients', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableClients, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableClients,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.syncClientsAttachments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('clients/clients/attachments/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('clients/clients/attachments', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableClientsAttachments, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableClientsAttachments,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.syncClientsContactPersons = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('clients/clients/contacts-persons/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('clients/clients/contacts-persons', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableClientsContactPersons, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableClientsContactPersons,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ClientsService.prototype.syncClientsBuildingMaps = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('clients/clients/building-maps/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('clients/clients/building-maps', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableClientsBuildingsMaps, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableClientsBuildingsMaps,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    return ClientsService;
}());
export { ClientsService };
