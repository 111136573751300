import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../../../_services/general.service';
import { FormsService } from '../../../../_services/forms.service';
import { InspectorsService } from '../../../../_services/inspectors.service';
import * as moment from 'moment';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { APP_DATE_FORMATS } from '../../../../_pipes/angular2-material-datepicker-custom-date';
var ɵ0 = APP_DATE_FORMATS;
var InspectorsCalibrationsCreateComponent = /** @class */ (function () {
    function InspectorsCalibrationsCreateComponent(generalService, inspectorsService, formsService, formBuilder, previousUrlService) {
        this.generalService = generalService;
        this.inspectorsService = inspectorsService;
        this.formsService = formsService;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.routerTransition = true;
        this.inspectorCalibrationForm = this.formBuilder.group({
            device_id: ['', [Validators.required, Validators.maxLength(100)]],
            device_brand: ['', [Validators.required, Validators.maxLength(100)]],
            device_description: ['', [Validators.required, Validators.maxLength(100)]],
            date_calibrated: ['', Validators.required],
            date_calibrated_input: [''],
            next_calibration_date: ['', Validators.required],
            next_calibration_date_input: [''],
            certificate_number: ['', Validators.required]
        });
        this._errorsSubscription = null;
    }
    InspectorsCalibrationsCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formsService.form = this.inspectorCalibrationForm;
        this.previousUrlService.setPreviousUrlIfNull('/inspectors/calibrations');
        this._errorsSubscription = this.inspectorsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
    };
    InspectorsCalibrationsCreateComponent.prototype.ngOnDestroy = function () {
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    };
    InspectorsCalibrationsCreateComponent.prototype.updateInspectorDateCalibrated = function ($event) {
        this.inspectorCalibrationForm.get('date_calibrated').setValue(moment($event.value).format('DD-MM-YYYY'));
        if (this.inspectorCalibrationForm.get('next_calibration_date').value === null || this.inspectorCalibrationForm.get('next_calibration_date').value === '') {
            var date = moment($event.value).add(1, 'years');
            this.inspectorCalibrationForm.get('next_calibration_date').setValue(date.format('DD-MM-YYYY'));
            this.inspectorCalibrationForm.get('next_calibration_date_input').setValue(date.format('YYYY-MM-DD'));
        }
    };
    InspectorsCalibrationsCreateComponent.prototype.updateInspectorNextCalibrationDate = function ($event) {
        this.inspectorCalibrationForm.get('next_calibration_date').setValue(moment($event.value).format('DD-MM-YYYY'));
    };
    return InspectorsCalibrationsCreateComponent;
}());
export { InspectorsCalibrationsCreateComponent };
export { ɵ0 };
