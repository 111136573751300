var __values = (this && this.__values) || function (o) {
    var m = typeof Symbol === "function" && o[Symbol.iterator], i = 0;
    if (m) return m.call(o);
    return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
};
import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../../_services/general.service';
import { FormsService } from '../../../_services/forms.service';
import { ClientsService } from '../../../_services/clients.service';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { debounceTime, tap } from 'rxjs/operators';
import { IndexedDBService } from '../../../_services/indexeddb.service';
import { ApiService } from '../../../_interceptors/api.service';
var ClientsCreateComponent = /** @class */ (function () {
    function ClientsCreateComponent(generalService, clientsService, formsService, previousUrlService, indexedDBService, apiService, formBuilder) {
        this.generalService = generalService;
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.previousUrlService = previousUrlService;
        this.indexedDBService = indexedDBService;
        this.apiService = apiService;
        this.formBuilder = formBuilder;
        this.routerTransition = true;
        this.languages = [];
        // noinspection JSMismatchedCollectionQueryUpdate
        this.countries = [];
        // noinspection JSMismatchedCollectionQueryUpdate
        this.countriesFiltered = [];
        this.isLoadingCountries = false;
        this.missingData = [];
        this.clientsCreateForm = this.formBuilder.group({
            company_name: ['', [Validators.required, Validators.maxLength(50)]],
            language_id: ['', Validators.required],
            language_name: [''],
            address: ['', [Validators.required, Validators.maxLength(100)]],
            postalcode: ['', [Validators.required, Validators.maxLength(10)]],
            city: ['', [Validators.required, Validators.maxLength(50)]],
            country_id: ['', Validators.required],
            country_id_filterString: [''],
            country_name: [''],
            telephone: ['', Validators.maxLength(20)],
            email: ['', [Validators.required, Validators.email]]
        });
        this._errorsSubscription = null;
    }
    ClientsCreateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.missingData.length > 0) {
                _this.missingData = [];
                _this.loadData();
            }
        });
        this.formsService.form = this.clientsCreateForm;
        this.previousUrlService.setPreviousUrlIfNull('/clients');
        this.loadData();
        this._errorsSubscription = this.clientsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this.clientsCreateForm
            .get('country_id_filterString')
            .valueChanges
            .pipe(debounceTime(300), tap(function () { return _this.isLoadingCountries = true; }))
            .subscribe(function (value) {
            if (typeof value === 'string') {
                if (value !== '' && value !== null) {
                    var val_1 = value.toLowerCase();
                    _this.countriesFiltered = _this.countries.filter(function (data) { return data.country_name.toLowerCase().indexOf(val_1) !== -1; });
                    _this.isLoadingCountries = false;
                }
                else if (value !== null) {
                    _this.countriesFiltered = _this.countries.slice();
                    _this.isLoadingCountries = false;
                }
            }
            else {
                _this.isLoadingCountries = false;
            }
        });
    };
    ClientsCreateComponent.prototype.loadData = function () {
        var _this = this;
        this.generalService.getLanguages().then(function (languages) {
            var e_1, _a;
            if (typeof languages !== 'undefined') {
                _this.clientsCreateForm.get('language_id').enable();
                _this.languages = languages;
                try {
                    for (var languages_1 = __values(languages), languages_1_1 = languages_1.next(); !languages_1_1.done; languages_1_1 = languages_1.next()) {
                        var language = languages_1_1.value;
                        if (language.language_name === 'Dutch') {
                            _this.clientsCreateForm.get('language_id').setValue(language.id);
                            break;
                        }
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (languages_1_1 && !languages_1_1.done && (_a = languages_1.return)) _a.call(languages_1);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            else {
                _this.missingData.push('Taal');
                _this.clientsCreateForm.get('language_id').disable();
            }
        }).catch(function (error) { return console.log(error); });
        this.generalService.getCountries().then(function (countries) {
            var e_2, _a;
            if (typeof countries !== 'undefined') {
                _this.clientsCreateForm.get('country_id').enable();
                _this.countries = countries;
                _this.countriesFiltered = countries;
                try {
                    for (var countries_1 = __values(countries), countries_1_1 = countries_1.next(); !countries_1_1.done; countries_1_1 = countries_1.next()) {
                        var country = countries_1_1.value;
                        if (country.country_name === 'Netherlands') {
                            _this.clientsCreateForm.get('country_id').setValue(country.id);
                            break;
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (countries_1_1 && !countries_1_1.done && (_a = countries_1.return)) _a.call(countries_1);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
            else {
                _this.missingData.push('Land');
                _this.clientsCreateForm.get('country_id').disable();
            }
        });
    };
    ClientsCreateComponent.prototype.ngOnDestroy = function () {
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    };
    ClientsCreateComponent.prototype.setLanguageName = function (language_name) {
        this.clientsCreateForm.get('language_name').setValue(language_name);
    };
    ClientsCreateComponent.prototype.setCountryName = function (country_name) {
        this.clientsCreateForm.get('country_name').setValue(country_name);
    };
    return ClientsCreateComponent;
}());
export { ClientsCreateComponent };
