var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from '../../../_services/general.service';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ReportsService } from '../../../_services/reports.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../_interceptors/api.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
import { ClientsService } from '../../../_services/clients.service';
var ClientsReportsListComponent = /** @class */ (function () {
    function ClientsReportsListComponent(reportsService, apiService, previousUrlService, activatedRouteService, generalService, clientsService, pageTitleService) {
        this.reportsService = reportsService;
        this.apiService = apiService;
        this.previousUrlService = previousUrlService;
        this.activatedRouteService = activatedRouteService;
        this.generalService = generalService;
        this.clientsService = clientsService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.client_id = null;
        this.reports = [];
        this._activatedRouteService = null;
        this.smallWidget = false;
    }
    ClientsReportsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._activatedRouteService = this.activatedRouteService.params.subscribe(function (params) {
            _this.client_id = parseInt(params['client_id'], 10);
            if (_this.smallWidget === false) {
                _this.clientsService.getClient(_this.client_id).then(function (client) {
                    if (typeof client !== 'undefined') {
                        _this.pageTitleService.setTitle(client.company_name + ' | Bekijk klant rapportage | ' + environment.webappName);
                        _this.pageTitleService.setPageTitle(client.company_name);
                    }
                });
            }
            _this.apiService.connectionChanged.subscribe(function (online) {
                if (online && _this.reports.length === 0) {
                    _this.currentPage = 0;
                    _this.search('');
                }
            });
            _this.search('');
        });
    };
    ClientsReportsListComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteService !== null) {
            this._activatedRouteService.unsubscribe();
        }
    };
    ClientsReportsListComponent.prototype.getReports = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading) {
            this.isLoading = true;
            this.currentPage++;
            this.reportsService.getClientReports(this.client_id, {
                start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
                length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        _this.totalItems = data.recordsTotal;
                    }
                    if (append_data === true) {
                        (_a = _this.reports).push.apply(_a, __spread(data.data));
                    }
                    else {
                        _this.reports = data.data;
                    }
                }
                _this.isLoading = false;
            });
        }
    };
    ClientsReportsListComponent.prototype.deleteReport = function (report_type, report_id) {
        var _this = this;
        this.reportsService.deleteReport(report_type, report_id).then(function (status) {
            if (status === true) {
                var index = _this.reports.findIndex(function (data) { return data.id === report_id; });
                if (index !== -1) {
                    _this.reports.splice(index, 1);
                }
            }
        });
    };
    ClientsReportsListComponent.prototype.search = function (event) {
        this.searchString = event;
        this.currentPage = 0;
        this.getReports(false);
    };
    return ClientsReportsListComponent;
}());
export { ClientsReportsListComponent };
