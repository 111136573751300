var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/Rx';
import { LoadingOverlayService } from './loading-overlay.service';
import { ApiService } from '../_interceptors/api.service';
import { SnackbarService } from './snackbar.service';
import { PreviousUrlService } from './previous-url.service';
import { MatDialog } from '@angular/material/dialog';
import { SimpleModalDialogComponent } from '../core/simple-modal/simple-modal.component';
import { IndexedDBService } from './indexeddb.service';
import * as moment from 'moment';
import { AuthenticationService } from './authentication.service';
import { ClientsService } from './clients.service';
import { LocalStorageService } from './local-storage.service';
import { environment } from '../../environments/environment';
import { ModalCopyReportDialogComponent } from '../core/modal-copy-report/modal-copy-report.component';
import { BuildingMapWidgetComponent } from '../core/building-map-widget/building-map-widget.component';
import * as _ from 'lodash';
var ReportsService = /** @class */ (function () {
    function ReportsService(apiService, clientsService, indexedDBService, dialogService, overlayService, snackbarService, previousUrlService, authenticationService, localStorageService, router) {
        this.apiService = apiService;
        this.clientsService = clientsService;
        this.indexedDBService = indexedDBService;
        this.dialogService = dialogService;
        this.overlayService = overlayService;
        this.snackbarService = snackbarService;
        this.previousUrlService = previousUrlService;
        this.authenticationService = authenticationService;
        this.localStorageService = localStorageService;
        this.router = router;
        this.errors = new BehaviorSubject(null);
        this.debug = !environment.production;
    }
    ReportsService.prototype.getClientReports = function (client_id, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_1 = [], collectionData_1 = [], startIndex_1 = 0, endIndex_1 = -1, searchString_1 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_1 = formData['start'];
                            endIndex_1 = formData['length'];
                            if (endIndex_1 !== -1) {
                                endIndex_1 += startIndex_1;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_1 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReports).then(function (reportsDB) {
                        if (typeof reportsDB !== 'undefined') {
                            reportsDB.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            collectionData_1 = reportsDB.filter(function (reports) { return reports.client_id === client_id && (typeof reports.indexedDBMethod === 'undefined' || typeof reports.indexedDBMethod !== 'undefined' && reports.indexedDBMethod !== '3-delete'); });
                            if (searchString_1 !== null) {
                                collectionData_1 = collectionData_1.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_1) !== -1; });
                            }
                            if (endIndex_1 !== -1) {
                                resultsData_1 = collectionData_1.slice(startIndex_1, endIndex_1);
                            }
                            else {
                                resultsData_1 = collectionData_1.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_1.length,
                                data: resultsData_1
                            });
                        }
                        else {
                            _this.syncReports().then(function (reports) {
                                collectionData_1 = reports.filter(function (reports2) { return reports2.client_id === client_id; });
                                if (searchString_1 !== null) {
                                    collectionData_1 = collectionData_1.filter(function (reports2) { return reports2.report_nr === searchString_1; });
                                }
                                if (endIndex_1 !== -1) {
                                    resultsData_1 = collectionData_1.slice(startIndex_1, endIndex_1);
                                }
                                else {
                                    resultsData_1 = collectionData_1.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData_1.length,
                                    data: resultsData_1
                                });
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                            resolve(undefined);
                        }
                    }, function () {
                        _this.syncReports().then(function (reports) {
                            collectionData_1 = reports.filter(function (reports2) { return reports2.client_id === client_id; });
                            if (searchString_1 !== null) {
                                collectionData_1 = collectionData_1.filter(function (reports2) { return reports2.report_nr === searchString_1; });
                            }
                            if (endIndex_1 !== -1) {
                                resultsData_1 = collectionData_1.slice(startIndex_1, endIndex_1);
                            }
                            else {
                                resultsData_1 = collectionData_1.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_1.length,
                                data: resultsData_1
                            });
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                        resolve(undefined);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getInspectors = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableInspectors).then(function (inspectorsDB) {
                        if (typeof inspectorsDB !== 'undefined') {
                            inspectorsDB.sort(function (a, b) { return a.firstname.toLowerCase() > b.firstname.toLowerCase() ? 1 : -1; });
                            resolve({
                                recordsTotal: inspectorsDB.length,
                                data: inspectorsDB
                            });
                        }
                        else {
                            _this.syncInspectors().then(function (inspectors) {
                                if (typeof inspectors !== 'undefined') {
                                    resolve({
                                        recordsTotal: inspectors.length,
                                        data: inspectors
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectors().then(function (inspectors) {
                            if (typeof inspectors !== 'undefined') {
                                resolve({
                                    recordsTotal: inspectors.length,
                                    data: inspectors
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingParticulars = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingParticulars).then(function (buildingParticularsDB) {
                        if (typeof buildingParticularsDB !== 'undefined') {
                            var collection = buildingParticularsDB.filter(function (particular) { return particular.active === true; });
                            collection.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection.length,
                                data: collection
                            });
                        }
                        else {
                            _this.syncBuildingParticulars().then(function (buildingParticulars) {
                                if (typeof buildingParticulars !== 'undefined') {
                                    var collection = buildingParticulars.filter(function (particular) { return particular.active === true; });
                                    resolve({
                                        recordsTotal: collection.length,
                                        data: collection
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectors().then(function (buildingParticulars) {
                            if (typeof buildingParticulars !== 'undefined') {
                                var collection = buildingParticulars.filter(function (particular) { return particular.active === true; });
                                resolve({
                                    recordsTotal: collection.length,
                                    data: collection
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingCategories = function (report_type, tent_star_rating) {
        var _this = this;
        if (tent_star_rating === void 0) { tent_star_rating = null; }
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast((report_type === 'warehouse' ? _this.indexedDBService.tableReportsWarehouseCategories : (report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderCategories :
                        _this.indexedDBService.tableReportsTentCategories))).then(function (categoriesDB) {
                        if (typeof categoriesDB !== 'undefined') {
                            var collection = categoriesDB.filter(function (category) { return category.active === true; })
                                .filter(function (category) { return tent_star_rating === null ? true : category.star_rating === tent_star_rating; });
                            collection.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection.length,
                                data: collection
                            });
                        }
                        else {
                            _this.syncCategories(report_type).then(function (categories) {
                                if (typeof categories !== 'undefined') {
                                    var collection = categories.filter(function (category) { return category.active === true; });
                                    resolve({
                                        recordsTotal: collection.length,
                                        data: collection
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncCategories(report_type).then(function (categories) {
                            if (typeof categories !== 'undefined') {
                                var collection = categories.filter(function (category) { return category.active === true; });
                                resolve({
                                    recordsTotal: collection.length,
                                    data: collection
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingSubcategories = function (report_type, category_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_1 = [];
                    _this.indexedDBService.database.getAllFast(report_type === 'warehouse' ? _this.indexedDBService.tableReportsWarehouseSubcategories :
                        report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderSubcategories :
                            _this.indexedDBService.tableReportsTentSubcategories).then(function (subcategoriesDB) {
                        if (typeof subcategoriesDB !== 'undefined') {
                            collection_1 = subcategoriesDB.filter(function (subcategory) { return subcategory.category_id === category_id && subcategory.active === true; });
                            collection_1.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_1.length,
                                data: collection_1
                            });
                        }
                        else {
                            _this.syncSubcategories(report_type).then(function (subcategories) {
                                if (typeof subcategories !== 'undefined') {
                                    collection_1 = subcategories.filter(function (subcategory) { return subcategory.category_id === category_id && subcategory.active === true; });
                                    resolve({
                                        recordsTotal: collection_1.length,
                                        data: collection_1
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncSubcategories(report_type).then(function (subcategories) {
                            if (typeof subcategories !== 'undefined') {
                                collection_1 = subcategories.filter(function (subcategory) { return subcategory.category_id === category_id && subcategory.active === true; });
                                resolve({
                                    recordsTotal: collection_1.length,
                                    data: collection_1
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingTypes = function (report_type, category_id, subcategory_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_2 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsWarehouseTypes).then(function (typesDB) {
                        if (typeof typesDB !== 'undefined') {
                            collection_2 = typesDB.filter(function (type) { return type.category_id === category_id && type.subcategory_id === subcategory_id && type.active === true; });
                            collection_2.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_2.length,
                                data: collection_2
                            });
                        }
                        else {
                            _this.syncTypes(report_type).then(function (types) {
                                if (typeof types !== 'undefined') {
                                    collection_2 = types.filter(function (type) { return type.category_id === category_id && type.subcategory_id === subcategory_id && type.active === true; });
                                    resolve({
                                        recordsTotal: collection_2.length,
                                        data: collection_2
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncTypes(report_type).then(function (types) {
                            if (typeof types !== 'undefined') {
                                collection_2 = types.filter(function (type) { return type.category_id === category_id && type.subcategory_id === subcategory_id && type.active === true; });
                                resolve({
                                    recordsTotal: collection_2.length,
                                    data: collection_2
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingActions = function (report_type, category_id, subcategory_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_3 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsWarehouseActions).then(function (actionsDB) {
                        if (typeof actionsDB !== 'undefined') {
                            collection_3 = actionsDB.filter(function (action) { return action.category_id === category_id && action.subcategory_id === subcategory_id && action.active === true; });
                            collection_3.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_3.length,
                                data: collection_3
                            });
                        }
                        else {
                            _this.syncActions(report_type).then(function (actions) {
                                if (typeof actions !== 'undefined') {
                                    collection_3 = actions.filter(function (action) { return action.category_id === category_id && action.subcategory_id === subcategory_id && action.active === true; });
                                    resolve({
                                        recordsTotal: collection_3.length,
                                        data: collection_3
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncActions(report_type).then(function (actions) {
                            if (typeof actions !== 'undefined') {
                                collection_3 = actions.filter(function (action) { return action.category_id === category_id && action.subcategory_id === subcategory_id && action.active === true; });
                                resolve({
                                    recordsTotal: collection_3.length,
                                    data: collection_3
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingMaterials = function (report_type) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsLadderMaterials).then(function (materialsDB) {
                        if (typeof materialsDB !== 'undefined') {
                            var collection = materialsDB.filter(function (material) { return material.active === true; });
                            collection.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection.length,
                                data: collection
                            });
                        }
                        else {
                            _this.syncMaterials(report_type).then(function (materials) {
                                if (typeof materials !== 'undefined') {
                                    var collection = materials.filter(function (material) { return material.active === true; });
                                    resolve({
                                        recordsTotal: collection.length,
                                        data: collection
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncMaterials(report_type).then(function (materials) {
                            if (typeof materials !== 'undefined') {
                                var collection = materials.filter(function (material) { return material.active === true; });
                                resolve({
                                    recordsTotal: collection.length,
                                    data: collection
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingQuestionnaires = function (report_type, category_id, subcategory_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_4 = [];
                    _this.indexedDBService.database.getAllFast(report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderQuestionnaire : _this.indexedDBService.tableReportsTentQuestionnaire).then(function (questionnairesDB) {
                        if (typeof questionnairesDB !== 'undefined') {
                            collection_4 = questionnairesDB.filter(function (collection2) { return collection2.category_id === category_id && collection2.subcategory_id === subcategory_id; });
                            collection_4.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_4.length,
                                data: collection_4
                            });
                        }
                        else {
                            _this.syncShortcomingQuestionnaires(report_type).then(function (questionnaires) {
                                if (typeof questionnaires !== 'undefined') {
                                    collection_4 = questionnaires.filter(function (questionnaire) { return questionnaire.category_id === category_id && questionnaire.subcategory_id === subcategory_id; });
                                    resolve({
                                        recordsTotal: collection_4.length,
                                        data: collection_4
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncShortcomingQuestionnaires(report_type).then(function (questionnaires) {
                            if (typeof questionnaires !== 'undefined') {
                                collection_4 = questionnaires.filter(function (questionnaire) { return questionnaire.category_id === category_id && questionnaire.subcategory_id === subcategory_id; });
                                resolve({
                                    recordsTotal: collection_4.length,
                                    data: collection_4
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingQuestionnaireAnswer = function (report_type, report_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_5 = [];
                    _this.indexedDBService.database.getByIndex((report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderQuestionnaireAnswers : _this.indexedDBService.tableReportsTentQuestionnaireAnswers), 'building_report_id', building_report_id).then(function (answersDB) {
                        if (typeof answersDB !== 'undefined') {
                            resolve(answersDB);
                        }
                        else {
                            _this.syncShortcomingQuestionnaireAnswers(report_type).then(function (answers) {
                                if (typeof answers !== 'undefined') {
                                    collection_5 = answers.filter(function (answer) { return answer.report_id === report_id && answer.building_report_id === building_report_id; });
                                    if (collection_5.length) {
                                        resolve(collection_5[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncShortcomingQuestionnaireAnswers(report_type).then(function (answers) {
                            if (typeof answers !== 'undefined') {
                                collection_5 = answers.filter(function (answer) { return answer.report_id === report_id && answer.building_report_id === building_report_id; });
                                if (collection_5.length) {
                                    resolve(collection_5[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getWarehouseQuestionnaires = function (report_type) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsWarehouseQuestionnaire).then(function (questionnairesDB) {
                        if (typeof questionnairesDB !== 'undefined') {
                            questionnairesDB.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: questionnairesDB.length,
                                data: questionnairesDB
                            });
                        }
                        else {
                            _this.syncWarehouseQuestionnaires(report_type).then(function (questionnaires) {
                                if (typeof questionnaires !== 'undefined') {
                                    resolve({
                                        recordsTotal: questionnaires.length,
                                        data: questionnaires
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncWarehouseQuestionnaires(report_type).then(function (questionnaires) {
                            if (typeof questionnaires !== 'undefined') {
                                resolve({
                                    recordsTotal: questionnaires.length,
                                    data: questionnaires
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getWarehouseQuestionnaireAnswer = function (report_type, report_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_6 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, report_id).then(function (answerDB) {
                        if (typeof answerDB !== 'undefined') {
                            resolve(answerDB);
                        }
                        else {
                            _this.syncWarehouseQuestionnaireAnswers(report_type).then(function (answers) {
                                if (typeof answers !== 'undefined') {
                                    collection_6 = answers.filter(function (answer) { return answer.report_id === report_id; });
                                    if (collection_6.length) {
                                        resolve(collection_6[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncWarehouseQuestionnaireAnswers(report_type).then(function (answers) {
                            if (typeof answers !== 'undefined') {
                                collection_6 = answers.filter(function (answer) { return answer.report_id === report_id; });
                                if (collection_6.length) {
                                    resolve(collection_6[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.saveReportQuestionnaire = function (report_type, report_id, formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/questionnaire', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Inspectischema opgeslagen.');
                    formData['report_id'] = report_id;
                    _this.indexedDBService.database.update(_this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, formData).then(function () {
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            formData['report_type'] = report_type;
            formData['report_id'] = report_id;
            formData['indexedDBMethod'] = '2-update';
            this.indexedDBService.database.update(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, formData).then(function () {
                _this.snackbarService.success('Inspectieschema opgeslagen.');
                _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                });
            }, function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        }
    };
    ReportsService.prototype.getClosedReports = function (report_type, report_id, formData) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        if (report_id === void 0) { report_id = null; }
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/closed' + (report_id !== null ? '/' + report_id : ''), formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getClosedReportAttachments = function (report_type, report_id, attachment_id, formData) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        if (attachment_id === void 0) { attachment_id = null; }
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/' + report_id + '/attachments' + (attachment_id !== null ? '/' + attachment_id : ''), formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getClosedReportComments = function (report_type, report_id, comment_id, formData) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        if (comment_id === void 0) { comment_id = null; }
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/' + report_id + '/comments' + (comment_id !== null ? '/' + comment_id : ''), formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getClosedReportBuildings = function (report_type, report_id, building_id, formData) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        if (building_id === void 0) { building_id = null; }
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/' + report_id + '/buildings' + (building_id !== null ? '/' + building_id : ''), formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getClosedReportBuildingReports = function (report_type, report_id, building_id, building_report_id, formData) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        if (building_report_id === void 0) { building_report_id = null; }
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports' + (building_report_id !== null ? '/' + building_report_id : ''), formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getClosedReportBuildingReport = function (report_type, report_id, building_id, building_report_id) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports' + '/' + building_report_id + '/view').then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    resolve(data.data);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getClosedWarehouseQuestionnaireAnswer = function (report_type, report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/' + report_id + '/questionnaire', {
                    start: 0,
                    length: -1
                }).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getReports = function (report_type, report_status, formData) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        if (report_status === void 0) { report_status = 'open'; }
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var results_1 = [], collection_7 = [], startIndex_2 = 0, endIndex_2 = -1, searchString_2 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_2 = formData['start'];
                            endIndex_2 = formData['length'];
                            if (endIndex_2 !== -1) {
                                endIndex_2 += startIndex_2;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_2 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReports).then(function (reportsDB) {
                        if (typeof reportsDB !== 'undefined') {
                            collection_7 = reportsDB.filter(function (reports) { return reports.type === report_type && reports.status === report_status && (typeof reports.indexedDBMethod === 'undefined' || typeof reports.indexedDBMethod !== 'undefined' && reports.indexedDBMethod !== '3-delete'); });
                            collection_7.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            if (searchString_2 !== null) {
                                collection_7 = collection_7.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_2) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_2) !== -1; });
                            }
                            if (endIndex_2 !== -1) {
                                results_1 = collection_7.slice(startIndex_2, endIndex_2);
                            }
                            else {
                                results_1 = collection_7.slice();
                            }
                            resolve({
                                recordsTotal: collection_7.length,
                                data: results_1
                            });
                        }
                        else {
                            _this.syncReports().then(function (reports) {
                                if (typeof reports !== 'undefined') {
                                    collection_7 = reports.filter(function (reports2) { return reports2.type === report_type && reports2.status === report_status; });
                                    if (searchString_2 !== null) {
                                        collection_7 = collection_7.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_2) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_2) !== -1; });
                                    }
                                    if (endIndex_2 !== -1) {
                                        results_1 = collection_7.slice(startIndex_2, endIndex_2);
                                    }
                                    else {
                                        results_1 = collection_7.slice();
                                    }
                                    resolve({
                                        recordsTotal: collection_7.length,
                                        data: results_1
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReports().then(function (reports) {
                            if (typeof reports !== 'undefined') {
                                collection_7 = reports.filter(function (reports2) { return reports2.type === report_type && reports2.status === report_status; });
                                if (searchString_2 !== null) {
                                    collection_7 = collection_7.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_2) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_2) !== -1; });
                                }
                                if (endIndex_2 !== -1) {
                                    results_1 = collection_7.slice(startIndex_2, endIndex_2);
                                }
                                else {
                                    results_1 = collection_7.slice();
                                }
                                resolve({
                                    recordsTotal: collection_7.length,
                                    data: results_1
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getReinspectionReports = function (formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var results_2 = [], startIndex_3 = 0, endIndex_3 = -1, searchString_3 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_3 = formData['start'];
                            endIndex_3 = formData['length'];
                            if (endIndex_3 !== -1) {
                                endIndex_3 += startIndex_3;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_3 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsReinspections).then(function (reportsDB) {
                        if (typeof reportsDB !== 'undefined') {
                            reportsDB.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            if (searchString_3 !== null) {
                                reportsDB = reportsDB.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_3) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_3) !== -1; });
                            }
                            if (endIndex_3 !== -1) {
                                results_2 = reportsDB.slice(startIndex_3, endIndex_3);
                            }
                            else {
                                results_2 = reportsDB.slice();
                            }
                            resolve({
                                recordsTotal: reportsDB.length,
                                data: results_2
                            });
                        }
                        else {
                            _this.syncReinspectionReports().then(function (reports) {
                                if (typeof reports !== 'undefined') {
                                    if (searchString_3 !== null) {
                                        reports = reports.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_3) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_3) !== -1; });
                                    }
                                    if (endIndex_3 !== -1) {
                                        results_2 = reports.slice(startIndex_3, endIndex_3);
                                    }
                                    else {
                                        results_2 = reports.slice();
                                    }
                                    resolve({
                                        recordsTotal: reports.length,
                                        data: results_2
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReinspectionReports().then(function (reports) {
                            if (typeof reports !== 'undefined') {
                                if (searchString_3 !== null) {
                                    reports = reports.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_3) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_3) !== -1; });
                                }
                                if (endIndex_3 !== -1) {
                                    results_2 = reports.slice(startIndex_3, endIndex_3);
                                }
                                else {
                                    results_2 = reports.slice();
                                }
                                resolve({
                                    recordsTotal: reports.length,
                                    data: results_2
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getReport = function (report_type, report_id) {
        var _this = this;
        if (report_type === void 0) { report_type = 'warehouse'; }
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_8 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (reportDB) {
                        if (typeof reportDB !== 'undefined') {
                            resolve(reportDB);
                        }
                        else {
                            _this.syncReports().then(function (reports) {
                                if (typeof reports !== 'undefined') {
                                    collection_8 = reports.filter(function (reports2) { return reports2.type === report_type && reports2.id === report_id && (typeof reports2.indexedDBMethod === 'undefined' || typeof reports2.indexedDBMethod !== 'undefined' && reports2.indexedDBMethod !== '3-delete'); });
                                    if (collection_8.length) {
                                        resolve(collection_8[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReports().then(function (reports) {
                            if (typeof reports !== 'undefined') {
                                collection_8 = reports.filter(function (reports2) { return reports2.type === report_type && reports2.id === report_id && (typeof reports2.indexedDBMethod === 'undefined' || typeof reports2.indexedDBMethod !== 'undefined' && reports2.indexedDBMethod !== '3-delete'); });
                                if (collection_8.length) {
                                    resolve(collection_8[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.createReport = function (report_type, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Rapportage opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReports, data.data).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        _this.router.navigate(['/reports/' + report_type + '/' + data.data.id + '/view']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports/' + report_type + '/open']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['created'] = moment().valueOf();
                formData['status'] = 'open';
                formData['status_text'] = 'Openstaand';
                formData['type'] = report_type;
                formData['type_text'] = report_type === 'warehouse' ? 'Magazijn' : 'Trappen';
                formData['edit_lock'] = true;
                formData['edit_lock_user_id'] = user.id;
                delete formData['account_user_id_filterString'];
                delete formData['client_filterString'];
                _this.indexedDBService.database.add(_this.indexedDBService.tableReports, formData).then(function () {
                    _this.snackbarService.success('Rapportage opgeslagen!');
                    formData['indexedDBMethod'] = '1-create';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, formData).then(function () {
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    _this.router.navigate(['/reports/' + report_type + '/' + formData['id'] + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.updateReport = function (report_type, report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Rapportage opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReports, data.data).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports/' + report_type + '/open']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(function (report) {
                delete formData['account_user_id_filterString'];
                delete formData['client_filterString'];
                var newReport = __assign({}, report, formData);
                newReport['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReports, newReport).then(function () {
                    _this.snackbarService.success('Rapportage opgeslagen!');
                    newReport['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, newReport).then(function () {
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            }, function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        }
    };
    ReportsService.prototype.deleteReport = function (report_type, report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze rapportage wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_type + '/' + report_id).then(function (data) {
                            if (data.success === true) {
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReports, report_id).then(function () {
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                                _this.snackbarService.success('Rapportage verwijderd.');
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReports, report_id).then(function () {
                            _this.snackbarService.success('Rapportage verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, {
                                id: report_id,
                                indexedDBMethod: '3-delete',
                                type: report_type
                            }).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                            _this.cleanupAfterReportDeletion([report_id]);
                            resolve(true);
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            resolve(false);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.sendToClient = function (report_type, report_id, formData) {
        var _this = this;
        if ((typeof formData['contact_persons_ids'] === 'undefined' || typeof formData['contact_persons_ids'] !== 'undefined' && formData['contact_persons_ids'].some(function (elem) { return elem !== false; })) || formData['custom_contacts'] !== '') {
            if (this.apiService.isOnline) {
                this.apiService.post('reports/' + report_type + '/' + report_id + '/send-to-client', formData).then(function (data) {
                    if (data.success === true) {
                        _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReports, 'id', report_id).then(function (report) {
                            report['status'] = 'verify';
                            report['status_text'] = 'Controle';
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report).then(function () {
                                _this.snackbarService.success('Rapportage verstuurd!');
                                _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report2) {
                                    report2.inspection_duration = ((formData['inspection_duration_day'] > 0 ? (formData['inspection_duration_day'] * 8) : 0) + formData['inspection_duration_hour']) + ':' + formData['inspection_duration_minute'] + ':00';
                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report2);
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                                _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                                });
                            }, function (error) {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        });
                    }
                    else if (typeof data.error !== 'undefined') {
                        _this.errors.next(data.error);
                    }
                    else {
                        _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    }
                });
            }
            else {
                this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(function (report) {
                    report['status'] = 'verify';
                    report['status_text'] = 'Controle';
                    _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report).then(function () {
                        formData['report_type'] = report_type;
                        formData['report_id'] = report_id;
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsSendToClient, formData).then(function () {
                            _this.snackbarService.success('Rapportage wordt verstuurd indien er weer een internet verbinding is.');
                            _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report2) {
                                report2.inspection_duration = ((formData['inspection_duration_day'] > 0 ? (formData['inspection_duration_day'] * 8) : 0) + formData['inspection_duration_hour']) + ':' + formData['inspection_duration_minute'] + ':00';
                                _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report2);
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                            _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                            });
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }, function (error) {
                        _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                });
            }
        }
        else {
            this.snackbarService.warning('Selecteer minimaal één contact persoon of voeg een email adres in handmatig');
        }
    };
    ReportsService.prototype.getAttachments = function (report_type, report_id, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_2 = [], collectionData_2 = [], startIndex_4 = 0, endIndex_4 = -1, searchString_4 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_4 = formData['start'];
                            endIndex_4 = formData['length'];
                            if (endIndex_4 !== -1) {
                                endIndex_4 += startIndex_4;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_4 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.getReport(report_type, report_id).then(function (report) {
                        if (typeof report !== 'undefined') {
                            var promises = [];
                            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableClientsAttachments).then(function (clientsAttachmentsDB) {
                                collectionData_2 = clientsAttachmentsDB.filter(function (attachments) { return attachments.client_id === report.client_id && (attachments.append_ladder === true && report_type === 'ladder' || attachments.append_warehouse === true && report_type === 'warehouse' || attachments.append_tent === true && report_type === 'tent'); });
                                return collectionData_2;
                            }));
                            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachments).then(function (attachmentsDB) {
                                if (typeof attachmentsDB !== 'undefined') {
                                    collectionData_2 = attachmentsDB.filter(function (attachments) { return attachments.report_id === report_id && (typeof attachments.indexedDBMethod === 'undefined' || typeof attachments.indexedDBMethod !== 'undefined' && attachments.indexedDBMethod !== '3-delete'); });
                                    return collectionData_2;
                                }
                                else {
                                    _this.syncReportsAttachments().then(function (attachments) {
                                        if (typeof attachments !== 'undefined') {
                                            collectionData_2 = attachments.filter(function (attachments2) { return attachments2.report_id === report_id; });
                                            return collectionData_2;
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }).catch(function (error) {
                                        reject();
                                        throw new Error(error);
                                    });
                                }
                            }, function () {
                                _this.syncReportsAttachments().then(function (attachments) {
                                    if (typeof attachments !== 'undefined') {
                                        collectionData_2 = attachments.filter(function (attachments2) { return attachments2.report_id === report_id; });
                                        return collectionData_2;
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    reject();
                                    throw new Error(error);
                                });
                            }));
                            Promise.all(promises).then(function (data) {
                                var collectionData2 = [];
                                data.forEach(function (d) { return collectionData2.push.apply(collectionData2, __spread(d)); });
                                collectionData2.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                                if (searchString_4 !== null) {
                                    collectionData2 = collectionData2.filter(function (attachments) { return attachments.title.toLowerCase().indexOf(searchString_4) !== -1; });
                                }
                                if (endIndex_4 !== -1) {
                                    resultsData_2 = collectionData2.slice(startIndex_4, endIndex_4);
                                }
                                else {
                                    resultsData_2 = collectionData2.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData2.length,
                                    data: resultsData_2
                                });
                            });
                        }
                    });
                }
            });
        });
    };
    ReportsService.prototype.createAttachment = function (report_type, report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/attachments', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Bijlage opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachments, data.data).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['indexedDBMethod'] = '1-create';
                formData['report_id'] = report_id;
                formData['report_type'] = report_type;
                formData['created'] = moment().valueOf();
                var newFormData = {
                    id: formData.id,
                    report_id: report_id,
                    filename: formData.filename._files[0].name,
                    original_filename: formData.filename._files[0].name,
                    title: formData['title'],
                    description: formData['description'],
                    inspector: user.display_name,
                    created: formData['created']
                };
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachments, newFormData).then(function () {
                    _this.snackbarService.success('Bijlage opgeslagen.');
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachmentsPush, formData);
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.deleteAttachment = function (report_type, report_id, attachment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze bijlage wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_type + '/' + report_id + '/attachments/' + attachment_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Bijlage verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsAttachments, attachment_id).then(function () {
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsAttachments, attachment_id).then(function () {
                            _this.snackbarService.success('Bijlage verwijderd.');
                            var formData = {
                                'report_type': report_type,
                                'report_id': report_id,
                                'id': attachment_id,
                                'indexedDBMethod': '3-delete'
                            };
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachmentsPush, formData).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getComments = function (report_type, report_id, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_3 = [], collectionData_3 = [], startIndex_5 = 0, endIndex_5 = -1, searchString_5 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_5 = formData['start'];
                            endIndex_5 = formData['length'];
                            if (endIndex_5 !== -1) {
                                endIndex_5 += startIndex_5;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_5 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsComments).then(function (commentsDB) {
                        if (typeof commentsDB !== 'undefined') {
                            collectionData_3 = commentsDB.filter(function (comments) { return comments.report_id === report_id && (typeof comments.indexedDBMethod === 'undefined' || typeof comments.indexedDBMethod !== 'undefined' && comments.indexedDBMethod !== '3-delete'); });
                            collectionData_3.sort(function (a, b) { return a.created > b.created ? 1 : -1; });
                            if (searchString_5 !== null) {
                                collectionData_3 = collectionData_3.filter(function (comments) { return comments.inspector.toLowerCase().indexOf(searchString_5) !== -1; });
                            }
                            if (endIndex_5 !== -1) {
                                resultsData_3 = collectionData_3.slice(startIndex_5, endIndex_5);
                            }
                            else {
                                resultsData_3 = collectionData_3.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_3.length,
                                data: resultsData_3
                            });
                        }
                        else {
                            _this.syncReportsComments().then(function (comments) {
                                if (typeof comments !== 'undefined') {
                                    collectionData_3 = comments.filter(function (comments2) { return comments2.report_id === report_id; });
                                    if (searchString_5 !== null) {
                                        collectionData_3 = collectionData_3.filter(function (comments2) { return comments2.inspector.toLowerCase().indexOf(searchString_5) !== -1; });
                                    }
                                    if (endIndex_5 !== -1) {
                                        resultsData_3 = collectionData_3.slice(startIndex_5, endIndex_5);
                                    }
                                    else {
                                        resultsData_3 = collectionData_3.slice();
                                    }
                                    resolve({
                                        recordsTotal: collectionData_3.length,
                                        data: resultsData_3
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsComments().then(function (comments) {
                            if (typeof comments !== 'undefined') {
                                collectionData_3 = comments.filter(function (comments2) { return comments2.report_id === report_id; });
                                if (searchString_5 !== null) {
                                    collectionData_3 = collectionData_3.filter(function (comments2) { return comments2.inspector.toLowerCase().indexOf(searchString_5) !== -1; });
                                }
                                if (endIndex_5 !== -1) {
                                    resultsData_3 = collectionData_3.slice(startIndex_5, endIndex_5);
                                }
                                else {
                                    resultsData_3 = collectionData_3.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData_3.length,
                                    data: resultsData_3
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getComment = function (report_type, report_id, comment_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_4 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsComments, comment_id).then(function (commentDB) {
                        if (typeof commentDB !== 'undefined') {
                            resolve(commentDB);
                        }
                        else {
                            _this.syncReportsComments().then(function (comments) {
                                if (typeof comments !== 'undefined') {
                                    resultsData_4 = comments.filter(function (comments2) { return comments2.report_id === report_id && comments2.id === comment_id; });
                                    if (resultsData_4.length) {
                                        resolve(resultsData_4[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsComments().then(function (comments) {
                            if (typeof comments !== 'undefined') {
                                resultsData_4 = comments.filter(function (comments2) { return comments2.report_id === report_id && comments2.id === comment_id; });
                                if (resultsData_4.length) {
                                    resolve(resultsData_4[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.createComment = function (report_type, report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/comments', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Opmerkingen opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsComments, data.data).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['report_id'] = report_id;
                formData['report_type'] = report_type;
                formData['account_user_id'] = user.id;
                formData['inspector'] = user.display_name;
                formData['created'] = moment().valueOf();
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsComments, formData).then(function () {
                    _this.snackbarService.success('Opmerkingen opgeslagen.');
                    formData['indexedDBMethod'] = '1-create';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCommentsPush, formData).then(function () {
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.updateComment = function (report_type, report_id, comment_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/comments/' + comment_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Opmerkingen opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsComments, data.data).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsComments, 'id', comment_id).then(function (comment) {
                comment['comment'] = formData['comment'];
                comment['report_type'] = report_type;
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsComments, comment).then(function () {
                    _this.snackbarService.success('Opmerkingen opgeslagen.');
                    comment['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCommentsPush, comment).then(function () {
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }, function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        }
    };
    ReportsService.prototype.getBuildings = function (report_type, report_id, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_5 = [], collectionData_4 = [], startIndex_6 = 0, endIndex_6 = -1, searchString_6 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_6 = formData['start'];
                            endIndex_6 = formData['length'];
                            if (endIndex_6 !== -1) {
                                endIndex_6 += startIndex_6;
                            }
                        }
                        if (typeof formData['columns[3][search][value]'] !== 'undefined') {
                            if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
                                searchString_6 = formData['columns[3][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (reportBuildingsDB) {
                        if (typeof reportBuildingsDB !== 'undefined') {
                            collectionData_4 = reportBuildingsDB.filter(function (collection2) { return collection2.report_id === report_id; });
                            collectionData_4.sort(function (a, b) { return a.sequence > b.sequence ? -1 : 1; });
                            if (searchString_6 !== null) {
                                collectionData_4 = collectionData_4.filter(function (collection2) { return collection2.name.toLowerCase().indexOf(searchString_6) !== -1; });
                            }
                            if (endIndex_6 !== -1) {
                                resultsData_5 = collectionData_4.slice(startIndex_6, endIndex_6);
                            }
                            else {
                                resultsData_5 = collectionData_4.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_4.length,
                                data: resultsData_5
                            });
                        }
                        else {
                            _this.syncReportsBuildings().then(function (reportsBuildings) {
                                if (typeof reportsBuildings !== 'undefined') {
                                    collectionData_4 = reportsBuildings.filter(function (reportsBuilding) { return reportsBuilding.report_id === report_id; });
                                    if (searchString_6 !== null) {
                                        collectionData_4 = collectionData_4.filter(function (reportsBuilding) { return reportsBuilding.name.toLowerCase().indexOf(searchString_6) !== -1; });
                                    }
                                    if (endIndex_6 !== -1) {
                                        resultsData_5 = collectionData_4.slice(startIndex_6, endIndex_6);
                                    }
                                    else {
                                        resultsData_5 = collectionData_4.slice();
                                    }
                                    resolve({
                                        recordsTotal: collectionData_4.length,
                                        data: resultsData_5
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsBuildings().then(function (reportsBuildings) {
                            if (typeof reportsBuildings !== 'undefined') {
                                collectionData_4 = reportsBuildings.filter(function (reportsBuilding) { return reportsBuilding.report_id === report_id; });
                                if (searchString_6 !== null) {
                                    collectionData_4 = collectionData_4.filter(function (reportsBuilding) { return reportsBuilding.name.toLowerCase().indexOf(searchString_6) !== -1; });
                                }
                                resolve({
                                    recordsTotal: collectionData_4.length,
                                    data: resultsData_5
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuilding = function (report_type, report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_6 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsBuildings, building_id).then(function (reportBuildingDB) {
                        if (typeof reportBuildingDB !== 'undefined') {
                            resolve(reportBuildingDB);
                        }
                        else {
                            _this.syncReportsBuildings().then(function (reportBuilding) {
                                if (typeof reportBuilding !== 'undefined') {
                                    resultsData_6 = reportBuilding.filter(function (collection3) { return collection3.report_id === report_id && collection3.id === building_id; });
                                    if (resultsData_6.length) {
                                        resolve(resultsData_6[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsBuildings().then(function (reportBuilding) {
                            if (typeof reportBuilding !== 'undefined') {
                                resultsData_6 = reportBuilding.filter(function (collection3) { return collection3.report_id === report_id && collection3.id === building_id; });
                                if (resultsData_6.length) {
                                    resolve(resultsData_6[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.createBuilding = function (report_type, report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Gebouw opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildings, data.data).then(function () {
                            if (typeof data.data.total_squares !== 'undefined') {
                                _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, data.data.report_id).then(function (report) {
                                    report.total_squares = data.data.total_squares;
                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report);
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + data.data.id + '/reports']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['report_id'] = report_id;
                formData['report_type'] = report_type;
                formData['edit_lock'] = true;
                formData['edit_lock_user_id'] = user.id;
                formData['created'] = moment().valueOf();
                formData['sequence'] = 1;
                formData['lib_particular_id'] = [];
                formData['particular_description'] = {};
                Object.keys(formData).map(function (key) {
                    if (key.indexOf('lib_particular_id[') !== -1) {
                        if (formData[key] !== false && formData[key] !== '') {
                            formData['lib_particular_id'].push(parseInt(key.replace('lib_particular_id[', '').replace(']', ''), 10));
                        }
                        delete formData[key];
                    }
                    if (key.indexOf('particular_description[') !== -1) {
                        if (formData[key] !== false && formData[key] !== '') {
                            formData['particular_description'][parseInt(key.replace('particular_description[', '').replace(']', ''), 10)] = formData[key];
                        }
                        delete formData[key];
                    }
                });
                delete formData['query'];
                _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (buildings) {
                    var total_squares = 0;
                    if (typeof buildings !== 'undefined') {
                        buildings.forEach(function (building) {
                            if (building.report_id === report_id) {
                                if (typeof building.sequence !== 'undefined') {
                                    if (formData['sequence'] <= parseInt(building.sequence, 10)) {
                                        formData['sequence'] = parseInt(building.sequence, 10) + 1;
                                    }
                                }
                                if (typeof building.squares !== 'undefined') {
                                    total_squares += building.squares;
                                }
                            }
                        });
                    }
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildings, formData).then(function () {
                        _this.snackbarService.success('Gebouw opgeslagen.');
                        formData['indexedDBMethod'] = '1-create';
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, formData).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report) {
                            report.total_squares = total_squares;
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report);
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + formData.id + '/reports']).then();
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.updateBuilding = function (report_type, report_id, building_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Gebouw opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildings, data.data).then(function () {
                            if (typeof data.data.total_squares !== 'undefined') {
                                _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, data.data.report_id).then(function (report) {
                                    report.total_squares = data.data.total_squares;
                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report);
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildings, 'id', building_id).then(function (building) {
                formData['lib_particular_id'] = [];
                formData['particular_description'] = {};
                Object.keys(formData).map(function (key) {
                    if (key.indexOf('lib_particular_id[') !== -1) {
                        if (formData[key] !== false && formData[key] !== '') {
                            formData['lib_particular_id'].push(parseInt(key.replace('lib_particular_id[', '').replace(']', ''), 10));
                        }
                        delete formData[key];
                    }
                    if (key.indexOf('particular_description[') !== -1) {
                        if (formData[key] !== false && formData[key] !== '') {
                            formData['particular_description'][parseInt(key.replace('particular_description[', '').replace(']', ''), 10)] = formData[key];
                        }
                        delete formData[key];
                    }
                });
                delete formData['query'];
                var newBuilding = __assign({}, building, formData);
                newBuilding['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildings, newBuilding).then(function () {
                    _this.snackbarService.success('Gebouw opgeslagen.');
                    newBuilding['indexedDBMethod'] = '2-update';
                    newBuilding['report_type'] = report_type;
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, newBuilding).then(function () {
                        _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (buildings) {
                            var total_squares = 0;
                            if (typeof buildings !== 'undefined') {
                                buildings.forEach(function (building2) {
                                    if (building2.report_id === report_id) {
                                        if (typeof building2.squares !== 'undefined') {
                                            total_squares += building2.squares;
                                        }
                                    }
                                });
                            }
                            _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report) {
                                report.total_squares = total_squares;
                                _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report);
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then();
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }, function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        }
    };
    ReportsService.prototype.deleteBuilding = function (report_type, report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze gebouw wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_type + '/' + report_id + '/buildings/' + building_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Gebouw verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildings, building_id).then(function () {
                                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (buildings) {
                                        var total_squares = 0;
                                        if (typeof buildings !== 'undefined') {
                                            buildings.forEach(function (building2) {
                                                if (building2.report_id === report_id) {
                                                    if (typeof building2.squares !== 'undefined') {
                                                        total_squares += building2.squares;
                                                    }
                                                }
                                            });
                                        }
                                        _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report) {
                                            report.total_squares = total_squares;
                                            _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report);
                                        }, function (error) {
                                            if (_this.debug) {
                                                console.log(error);
                                            }
                                            throw new Error(error);
                                        });
                                    }, function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Fout bij verwijderen Gebouw.');
                                resolve(false);
                            }
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildings, building_id).then(function () {
                            _this.snackbarService.success('Gebouw verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, {
                                id: building_id,
                                report_id: report_id,
                                report_type: report_type,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                                _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (buildings) {
                                    var total_squares = 0;
                                    if (typeof buildings !== 'undefined') {
                                        buildings.forEach(function (building2) {
                                            if (building2.report_id === report_id && building2.id !== building_id) {
                                                if (typeof building2.squares !== 'undefined') {
                                                    total_squares += building2.squares;
                                                }
                                            }
                                        });
                                    }
                                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report) {
                                        report.total_squares = total_squares;
                                        _this.indexedDBService.database.update(_this.indexedDBService.tableReports, report);
                                    }, function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                            _this.deleteReportBuildingsReports(report_id, building_id).then(function () {
                            });
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getBuildingReports = function (report_type, report_id, building_id, formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_7 = [], collectionData_5 = [], startIndex_7 = 0, endIndex_7 = -1, searchString_7 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_7 = formData['start'];
                            endIndex_7 = formData['length'];
                            endIndex_7 += startIndex_7;
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_7 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReports).then(function (buildingReportsDB) {
                        if (typeof buildingReportsDB !== 'undefined') {
                            collectionData_5 = buildingReportsDB.filter(function (collection2) { return collection2.report_id === report_id && collection2.building_id === building_id && (typeof collection2.indexedDBMethod === 'undefined' || typeof collection2.indexedDBMethod !== 'undefined' && collection2.indexedDBMethod !== '3-delete'); });
                            collectionData_5.sort(function (a, b) { return a.sequence > b.sequence ? -1 : 1; });
                            if (searchString_7 !== null) {
                                collectionData_5 = collectionData_5.filter(function (collection2) { return collection2.location_nr.toLowerCase().indexOf(searchString_7) !== -1; });
                            }
                            if (endIndex_7 !== -1) {
                                resultsData_7 = collectionData_5.slice(startIndex_7, endIndex_7);
                            }
                            else {
                                resultsData_7 = collectionData_5.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_5.length,
                                data: resultsData_7
                            });
                        }
                        else {
                            _this.syncReportsBuildingsReports().then(function (buildingReports) {
                                if (typeof buildingReports !== 'undefined') {
                                    collectionData_5 = buildingReports.filter(function (collection3) { return collection3.report_id === report_id && collection3.building_id === building_id; });
                                    if (searchString_7 !== null) {
                                        collectionData_5 = collectionData_5.filter(function (collection3) { return collection3.location_nr.toLowerCase().indexOf(searchString_7) !== -1; });
                                    }
                                    if (endIndex_7 !== -1) {
                                        resultsData_7 = collectionData_5.slice(startIndex_7, endIndex_7);
                                    }
                                    else {
                                        resultsData_7 = collectionData_5.slice();
                                    }
                                    resolve({
                                        recordsTotal: collectionData_5.length,
                                        data: resultsData_7
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsBuildingsReports().then(function (buildingReports) {
                            if (typeof buildingReports !== 'undefined') {
                                collectionData_5 = buildingReports.filter(function (collection3) { return collection3.report_id === report_id && collection3.building_id === building_id; });
                                if (searchString_7 !== null) {
                                    collectionData_5 = collectionData_5.filter(function (collection3) { return collection3.location_nr.toLowerCase().indexOf(searchString_7) !== -1; });
                                }
                                if (endIndex_7 !== -1) {
                                    resultsData_7 = collectionData_5.slice(startIndex_7, endIndex_7);
                                }
                                else {
                                    resultsData_7 = collectionData_5.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData_5.length,
                                    data: resultsData_7
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingReport = function (report_type, report_id, building_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_8 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(function (buildingReportDB) {
                        if (typeof buildingReportDB !== 'undefined') {
                            resolve(buildingReportDB);
                        }
                        else {
                            _this.syncReportsBuildingsReports().then(function (buildingReport) {
                                if (typeof buildingReport !== 'undefined') {
                                    resultsData_8 = buildingReport.filter(function (collection3) { return collection3.report_id === report_id && collection3.building_id === building_id && collection3.id === building_report_id; });
                                    if (resultsData_8.length) {
                                        resolve(resultsData_8[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsBuildingsReports().then(function (buildingReport) {
                            if (typeof buildingReport !== 'undefined') {
                                resultsData_8 = buildingReport.filter(function (collection3) { return collection3.report_id === report_id && collection3.building_id === building_id && collection3.id === building_report_id; });
                                if (resultsData_8.length) {
                                    resolve(resultsData_8[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingMapMarkers = function (report_id, building_id, shortcoming_id) {
        var _this = this;
        if (shortcoming_id === void 0) { shortcoming_id = null; }
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collectionData_6 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsMaps).then(function (buildingReportsDB) {
                        if (typeof buildingReportsDB !== 'undefined') {
                            collectionData_6 = buildingReportsDB.filter(function (collection2) { return collection2.report_id === report_id &&
                                collection2.building_id === building_id &&
                                (shortcoming_id !== null ? collection2.shortcoming_id === shortcoming_id : '' === '') &&
                                (typeof collection2.indexedDBMethod === 'undefined' ||
                                    typeof collection2.indexedDBMethod !== 'undefined' &&
                                        collection2.indexedDBMethod !== '3-delete'); });
                            resolve(collectionData_6);
                        }
                        else {
                            _this.syncReportsBuildingsMapMarkers().then(function (buildingReports) {
                                if (typeof buildingReports !== 'undefined') {
                                    collectionData_6 = buildingReports.filter(function (collection3) { return collection3.report_id === report_id &&
                                        collection3.building_id === building_id &&
                                        (shortcoming_id !== null ? collection3.shortcoming_id === shortcoming_id : '' === ''); });
                                    resolve(collectionData_6);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncReportsBuildingsMapMarkers().then(function (buildingReports) {
                            if (typeof buildingReports !== 'undefined') {
                                collectionData_6 = buildingReports.filter(function (collection3) { return collection3.report_id === report_id &&
                                    collection3.building_id === building_id &&
                                    (shortcoming_id !== null ? collection3.shortcoming_id === shortcoming_id : '' === ''); });
                                resolve(collectionData_6);
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.createBuildingReport = function (report_type, report_id, building_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Schade opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReports, data.data).then(function () {
                            if (typeof data.data.markers !== 'undefined') {
                                if (data.data.markers.length) {
                                    data.data.markers.forEach(function (marker) {
                                        marker['shortcoming_id'] = data.data.id;
                                        marker['report_id'] = report_id;
                                        marker['building_id'] = building_id;
                                        marker['type'] = report_type;
                                        marker['location_nr'] = data.data.location_nr;
                                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsMaps, marker).then(function () {
                                        });
                                    });
                                }
                            }
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    if (typeof formData['ladder_questions'] !== 'undefined') {
                        var answers_1 = {
                            report_id: report_id,
                            building_report_id: data.data.id,
                            group_id: null,
                            questions: {}
                        };
                        Object.keys(formData['ladder_questions']).map(function (questionnaire_id) {
                            var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                            answers_1.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                            if (formData['ladder_questions'][questionnaire_id] !== false) {
                                answers_1.questions[key] = formData['ladder_questions'][questionnaire_id];
                            }
                        });
                        if (_.isEmpty(answers_1.questions)) {
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers_1).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    if (typeof formData['tent_questions'] !== 'undefined') {
                        var answers_2 = {
                            report_id: report_id,
                            building_report_id: data.data.id,
                            group_id: null,
                            questions: {}
                        };
                        Object.keys(formData['tent_questions']).map(function (questionnaire_id) {
                            var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                            answers_2.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                            if (formData['tent_questions'][questionnaire_id] !== false) {
                                answers_2.questions[key] = formData['tent_questions'][questionnaire_id];
                            }
                        });
                        if (_.isEmpty(answers_2.questions)) {
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers_2).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                delete formData['photosContainer[]'];
                formData['id'] = moment().valueOf();
                formData['report_type'] = report_type;
                formData['report_id'] = report_id;
                formData['building_id'] = building_id;
                formData['verify_status'] = false;
                formData['verify_inspector_status'] = false;
                formData['created'] = moment().valueOf();
                formData['edit_lock'] = true;
                formData['edit_lock_user_id'] = user.id;
                formData['created'] = moment().valueOf();
                formData['sequence'] = 1;
                if (typeof formData['warehouse_action_ids'] !== 'undefined') {
                    formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(function (data) { return data !== false; });
                }
                if (typeof formData['warehouse_type_ids'] !== 'undefined') {
                    formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(function (data) { return data !== false; });
                }
                if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
                    var tmp_1 = {};
                    Object.keys(formData['warehouse_type_field_value']).map(function (key) {
                        if (formData['warehouse_type_field_value'][key] !== '') {
                            tmp_1[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
                        }
                    });
                    formData['warehouse_type_field_value'] = tmp_1;
                }
                var pushPhotos = [], reportPhotos = [];
                if (typeof formData['photos'] !== 'undefined') {
                    if (formData['photos'].length) {
                        formData['photos'].forEach(function (photo) {
                            var id = crypto['randomUUID']();
                            pushPhotos.push({
                                id: id,
                                file: photo
                            });
                            reportPhotos.push({
                                filename: photo.name,
                                id: id,
                                offline: true
                            });
                        });
                        formData['photos'] = {
                            inspector: reportPhotos
                        };
                    }
                    if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
                        delete formData['photos'];
                    }
                }
                if (typeof formData['color_code'] !== 'undefined') {
                    if (formData['color_code'] === '0') {
                        formData['color'] = '#ed7c20';
                        formData['color_text'] = 'Oranje';
                    }
                    else if (formData['color_code'] === '1') {
                        formData['color'] = '#cc0000';
                        formData['color_text'] = 'Rood';
                    }
                    else if (formData['color_code'] === '2') {
                        formData['color'] = '#019342';
                        formData['color_text'] = 'Groen';
                    }
                }
                _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReports).then(function (buildingReports) {
                    if (typeof buildingReports !== 'undefined') {
                        buildingReports.forEach(function (buildingReport) {
                            if (buildingReport.report_id === report_id && buildingReport.building_id === building_id) {
                                if (typeof buildingReport.sequence !== 'undefined') {
                                    if (formData['sequence'] <= buildingReport.sequence) {
                                        formData['sequence'] = parseInt(buildingReport.sequence, 10) + 1;
                                    }
                                }
                            }
                        });
                    }
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReports, formData).then(function () {
                        _this.snackbarService.success('Schade opgeslagen.');
                        if (typeof formData['ladder_questions'] !== 'undefined') {
                            var answers_3 = {
                                report_id: report_id,
                                report_type: report_type,
                                group_id: null,
                                building_report_id: formData.id,
                                questions: {}
                            };
                            Object.keys(formData['ladder_questions']).map(function (questionnaire_id) {
                                var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                                answers_3.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                                if (formData['ladder_questions'][questionnaire_id] !== false) {
                                    answers_3.questions[key] = formData['ladder_questions'][questionnaire_id];
                                }
                            });
                            if (_.isEmpty(answers_3.questions)) {
                                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers_3).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                        if (typeof formData['tent_questions'] !== 'undefined') {
                            var answers_4 = {
                                report_id: report_id,
                                report_type: report_type,
                                group_id: null,
                                building_report_id: formData.id,
                                questions: {}
                            };
                            Object.keys(formData['tent_questions']).map(function (questionnaire_id) {
                                var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                                answers_4.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                                if (formData['tent_questions'][questionnaire_id] !== false) {
                                    answers_4.questions[key] = formData['tent_questions'][questionnaire_id];
                                }
                            });
                            if (_.isEmpty(answers_4.questions)) {
                                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers_4).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }
                        formData['indexedDBMethod'] = '1-create';
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, formData).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                        if (pushPhotos.length) {
                            var reportPhotos2 = {
                                report_id: report_id,
                                report_type: report_type,
                                building_id: building_id,
                                building_report_id: formData.id,
                                indexedDBMethod: '1-create',
                                photos: pushPhotos
                            };
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                        _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                        });
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.updateBuildingReport = function (report_type, report_id, building_id, building_report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Schade opgeslagen.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, data.data).then(function () {
                            if (typeof data.data.markers !== 'undefined') {
                                if (data.data.markers.length) {
                                    data.data.markers.forEach(function (marker) {
                                        marker['shortcoming_id'] = data.data.id;
                                        marker['report_id'] = report_id;
                                        marker['building_id'] = building_id;
                                        marker['type'] = report_type;
                                        marker['location_nr'] = data.data.location_nr;
                                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsMaps, marker).then(function () {
                                        });
                                    });
                                }
                                else {
                                    // get all markers in database
                                    // delete them all
                                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsMaps).then(function (markersDB) {
                                        var ids = markersDB.filter(function (d) { return d.shortcoming_id === building_report_id; });
                                        if (ids.length) {
                                            _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsMaps, ids.map(function (d) { return d.id; })).then(function () {
                                            });
                                        }
                                    });
                                }
                            }
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    if (typeof formData['ladder_questions'] !== 'undefined') {
                        var answers_5 = {
                            report_id: report_id,
                            group_id: null,
                            building_report_id: data.data.id,
                            questions: {}
                        };
                        Object.keys(formData['ladder_questions']).map(function (questionnaire_id) {
                            var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                            answers_5.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                            if (formData['ladder_questions'][questionnaire_id] !== false) {
                                answers_5.questions[key] = formData['ladder_questions'][questionnaire_id];
                            }
                        });
                        if (_.isEmpty(answers_5.questions)) {
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers_5).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    if (typeof formData['tent_questions'] !== 'undefined') {
                        var answers_6 = {
                            report_id: report_id,
                            group_id: null,
                            building_report_id: data.data.id,
                            questions: {}
                        };
                        Object.keys(formData['tent_questions']).map(function (questionnaire_id) {
                            var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                            answers_6.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                            if (formData['tent_questions'][questionnaire_id] !== false) {
                                answers_6.questions[key] = formData['tent_questions'][questionnaire_id];
                            }
                        });
                        if (_.isEmpty(answers_6.questions)) {
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers_6).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(function (report) {
                delete formData['photosContainer[]'];
                if (typeof formData['warehouse_action_ids'] !== 'undefined') {
                    formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(function (data) { return data !== false; });
                }
                if (typeof formData['warehouse_type_ids'] !== 'undefined') {
                    formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(function (data) { return data !== false; });
                }
                if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
                    var tmp_2 = {};
                    Object.keys(formData['warehouse_type_field_value']).map(function (key) {
                        if (formData['warehouse_type_field_value'][key] !== '') {
                            tmp_2[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
                        }
                    });
                    formData['warehouse_type_field_value'] = tmp_2;
                }
                var pushPhotos = [], reportPhotos = [];
                if (typeof formData['photos'] !== 'undefined') {
                    if (typeof report['photos'] !== 'undefined') {
                        if (typeof report['photos']['inspector'] !== 'undefined') {
                            reportPhotos = report['photos']['inspector'];
                        }
                    }
                    if (formData['photos'].length) {
                        formData['photos'].forEach(function (photo) {
                            var id = crypto['randomUUID']();
                            pushPhotos.push({
                                id: id,
                                file: photo
                            });
                            reportPhotos.push({
                                filename: photo.name,
                                id: id,
                                offline: true
                            });
                        });
                        formData['photos'] = {
                            inspector: reportPhotos
                        };
                    }
                    if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
                        delete formData['photos'];
                    }
                }
                if (typeof formData['color_code'] !== 'undefined') {
                    if (formData['color_code'] === '0') {
                        formData['color'] = '#ed7c20';
                        formData['color_text'] = 'Oranje';
                    }
                    else if (formData['color_code'] === '1') {
                        formData['color'] = '#cc0000';
                        formData['color_text'] = 'Rood';
                    }
                    else if (formData['color_code'] === '2') {
                        formData['color'] = '#019342';
                        formData['color_text'] = 'Groen';
                    }
                }
                formData['report_type'] = report_type;
                var newReport = __assign({}, report, formData);
                newReport['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, newReport).then(function () {
                    _this.snackbarService.success('Schade opgeslagen.');
                    if (typeof formData['ladder_questions'] !== 'undefined') {
                        var answers_7 = {
                            report_id: report_id,
                            report_type: report_type,
                            group_id: null,
                            building_report_id: building_report_id,
                            questions: {}
                        };
                        Object.keys(formData['ladder_questions']).map(function (questionnaire_id) {
                            var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                            answers_7.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                            if (formData['ladder_questions'][questionnaire_id] !== false) {
                                answers_7.questions[key] = formData['ladder_questions'][questionnaire_id];
                            }
                        });
                        if (_.isEmpty(answers_7.questions)) {
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReportsLadderQuestionnaireAnswers, answers_7).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    if (typeof formData['tent_questions'] !== 'undefined') {
                        var answers_8 = {
                            report_id: report_id,
                            report_type: report_type,
                            group_id: null,
                            building_report_id: building_report_id,
                            questions: {}
                        };
                        Object.keys(formData['tent_questions']).map(function (questionnaire_id) {
                            var key = parseInt(questionnaire_id.match(/\[(\d+)]/g)[1].replace('[', '').replace(']', ''), 10);
                            answers_8.group_id = parseInt(questionnaire_id.match(/\[(\d+)]/g)[0].replace('[', '').replace(']', ''), 10);
                            if (formData['tent_questions'][questionnaire_id] !== false) {
                                answers_8.questions[key] = formData['tent_questions'][questionnaire_id];
                            }
                        });
                        if (_.isEmpty(answers_8.questions)) {
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReportsTentQuestionnaireAnswers, answers_8).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    newReport['report_type'] = report_type;
                    newReport['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, newReport).then(function () {
                    }, function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                    if (pushPhotos.length) {
                        var reportPhotos2_1 = {
                            report_id: report_id,
                            report_type: report_type,
                            building_id: building_id,
                            building_report_id: building_report_id,
                            indexedDBMethod: '1-create',
                            photos: pushPhotos
                        };
                        _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(function (photos) {
                            if (typeof photos !== 'undefined') {
                                if (typeof photos.photos !== 'undefined') {
                                    photos.photos = photos.photos.concat(pushPhotos);
                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReportsPhotos, photos).then(function () {
                                    }, function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }
                                else {
                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2_1).then(function () {
                                    }, function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }
                            }
                            else {
                                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2_1).then(function () {
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                    });
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }, function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        }
    };
    ReportsService.prototype.deleteBuildingReport = function (report_type, report_id, building_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze contactpersoon wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then(function (data) {
                            if (data.success === true) {
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(function () {
                                }, function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                                _this.snackbarService.success('Schade verwijderd.');
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(function () {
                            _this.snackbarService.success('Schade verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, {
                                id: building_report_id,
                                report_type: report_type,
                                report_id: report_id,
                                building_id: building_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                            _this.deleteReportShortcomingQuestionnaireAnswers(report_id, [building_report_id]).then(function () {
                            });
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.deleteBuildingReportPhoto = function (report_type, report_id, building_id, building_report_id, photo_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze foto wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/delete-image/' + photo_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Foto verwijderd.');
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(function (report) {
                            var was_photo_removed = false, promise = [];
                            if (typeof report !== 'undefined') {
                                if (typeof report.photos !== 'undefined') {
                                    if (report.photos.length) {
                                        var photo = report.photos.filter(function (data) { return data.id === photo_id; });
                                        if (photo.length) {
                                            photo = report.photos.indexOf(photo[0]);
                                            report.photos.splice(photo, 1);
                                            if (report.photos.length) {
                                                promise.push(_this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReportsPhotos, report).then(function () {
                                                    was_photo_removed = true;
                                                }, function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                }));
                                            }
                                            else {
                                                promise.push(_this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(function () {
                                                    was_photo_removed = true;
                                                }, function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                }));
                                            }
                                            _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(function (report2) {
                                                if (typeof report2 !== 'undefined') {
                                                    if (typeof report2.photos !== 'undefined') {
                                                        if (typeof report2.photos.inspector !== 'undefined') {
                                                            if (report2.photos.inspector.length) {
                                                                var photo2 = report2.photos.inspector.filter(function (data) { return data.id === photo_id; });
                                                                if (photo2.length) {
                                                                    photo2 = report2.photos.inspector.indexOf(photo2[0]);
                                                                    report2.photos.inspector.splice(photo2, 1);
                                                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, report2).then(function () {
                                                                        _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReportsPush).then(function (reports3) {
                                                                            if (typeof reports3 !== 'undefined') {
                                                                                var report3 = reports3.filter(function (data) { return data.id === building_report_id; })[0];
                                                                                if (typeof report3.photos !== 'undefined') {
                                                                                    if (typeof report3.photos.inspector !== 'undefined') {
                                                                                        if (report3.photos.inspector.length) {
                                                                                            var photo3 = report3.photos.inspector.filter(function (data) { return data.id === photo_id; });
                                                                                            if (photo3.length) {
                                                                                                photo3 = report3.photos.inspector.indexOf(photo3[0]);
                                                                                                report3.photos.inspector.splice(photo3, 1);
                                                                                                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReportsPush, report3).then(function () {
                                                                                                }, function (error) { return new Error(error); });
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                    }, function (error) { return new Error(error); });
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                            _this.snackbarService.success('Foto verwijderd.');
                                        }
                                    }
                                }
                            }
                            Promise.all(promise).then(function () {
                                if (was_photo_removed === false) {
                                    var formData = {
                                        id: photo_id,
                                        report_type: report_type,
                                        report_id: report_id,
                                        building_id: building_id,
                                        building_report_id: building_report_id,
                                        indexedDBMethod: '3-delete'
                                    };
                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, formData).then(function () {
                                        _this.snackbarService.success('Foto verwijderd.');
                                        resolve(true);
                                    }, function (error) { return new Error(error); });
                                }
                            });
                            resolve(true);
                        }, function (error) { return new Error(error); });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getOfflinePhotos = function (report_id, building_id, building_report_id, photo_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.database.getAll(_this.indexedDBService.tableReportsBuildingsReportsPhotos).then(function (reports) {
                reports.forEach(function (report) {
                    if (report.report_id === report_id && report.building_id === building_id && report.building_report_id === building_report_id && typeof report.photos !== 'undefined') {
                        if (typeof report.photos !== 'undefined') {
                            var photo = report.photos.filter(function (data) { return data.id === photo_id; })[0], file = photo.file, reader_1 = new FileReader();
                            reader_1.onloadend = function () {
                                resolve(reader_1.result);
                            };
                            reader_1.readAsDataURL(file);
                        }
                    }
                });
            }, function (error) {
                if (_this.debug) {
                    if (_this.debug) {
                        console.log(error);
                    }
                }
                resolve(undefined);
                throw new Error(error);
            });
        });
    };
    ReportsService.prototype.verifyInspectorStatus = function (report_type, report_id, building_id, building_report_id, status) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.post('reports/' + report_type + '/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/verify-inspector-status', {
                    status: status
                }).then(function (data) {
                    if (data.success === true) {
                        // this.snackbarService.success('Schade opgeslagen.');
                        if (typeof data.data !== 'undefined') {
                            _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, data.data).then(function () {
                                resolve(status);
                            }, function (error) {
                                if (_this.debug) {
                                    console.log(error);
                                }
                                throw new Error(error);
                            });
                        }
                    }
                    else if (typeof data.error !== 'undefined') {
                        _this.errors.next(data.error);
                    }
                    else {
                        _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    }
                });
            }
            else {
                var formData = {
                    'report_type': report_type,
                    'report_id': report_id,
                    'building_id': building_id,
                    'building_report_id': building_report_id,
                    'status': status
                };
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsVerifyInspectorStatus, formData).then(function () {
                    resolve(status);
                    // this.snackbarService.success('Schade opgeslagen.');
                }, function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
        });
    };
    ReportsService.prototype.downloadFile = function (url, filename) {
        if (this.apiService.isOnline) {
            if (typeof url !== 'undefined') {
                this.apiService.download(url, filename);
            }
            else {
                this.snackbarService.error('Ongeldige link, kan niet worden gedownload.');
            }
        }
        else {
            this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
        }
    };
    ReportsService.prototype.checkBarcodeScanner = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('configuration/settings').then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.data !== 'undefined') {
                            if (typeof data.data.barcode_scanner !== 'undefined') {
                                _this.localStorageService.set('barcode_scanner', data.data.barcode_scanner);
                                if (data.data.barcode_scanner === '1') {
                                    resolve(true);
                                }
                                else {
                                    resolve(false);
                                }
                            }
                            else {
                                resolve(false);
                            }
                        }
                        else {
                            resolve(false);
                        }
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                var setting = _this.localStorageService.get('barcode_scanner');
                if (setting !== null) {
                    if (setting === '1') {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
            }
        });
    };
    ReportsService.prototype.checkSpeechToText = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('configuration/settings').then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.data !== 'undefined') {
                            if (typeof data.data.speech_to_text !== 'undefined') {
                                _this.localStorageService.set('speech_to_text', data.data.speech_to_text);
                                if (data.data.speech_to_text === '1') {
                                    resolve(true);
                                }
                                else {
                                    resolve(false);
                                }
                            }
                            else {
                                resolve(false);
                            }
                        }
                        else {
                            resolve(false);
                        }
                    }
                    else {
                        resolve(false);
                    }
                });
            }
            else {
                var setting = _this.localStorageService.get('speech_to_text');
                if (setting !== null) {
                    if (setting === '1') {
                        resolve(true);
                    }
                    else {
                        resolve(false);
                    }
                }
            }
        });
    };
    ReportsService.prototype.isLadderLocationNrUnique = function (report_id, building_id, location_number) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/is-location-unique/' + location_number).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isExistingLadderLocationNrUnique = function (report_id, building_id, building_report_id, location_number) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-location-unique/' + location_number).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isLadderIDUnique = function (report_id, building_id, id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/is-id-unique/' + id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isExistingLadderIDUnique = function (report_id, building_id, building_report_id, id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-id-unique/' + id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getNextLadderLocationNr = function (report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/ladders/' + report_id + '/buildings/' + building_id + '/next-ladder-location-nr').then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isTentLocationNrUnique = function (report_id, building_id, location_number) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/is-tent-location-unique/' + location_number).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isExistingTentLocationNrUnique = function (report_id, building_id, building_report_id, location_number) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-tent-location-unique/' + location_number).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isTentIDUnique = function (report_id, building_id, id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/is-tent-id-unique/' + id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.isExistingTentIDUnique = function (report_id, building_id, building_report_id, id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/is-tent-id-unique/' + id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getNextTentLocationNr = function (report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/tent/' + report_id + '/buildings/' + building_id + '/next-tent-location-nr').then(function (data) {
                    if (typeof data !== 'undefined') {
                        resolve(data);
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.copyReport = function (report_type, report_id) {
        var _this = this;
        return new Promise(function () {
            var dialogRef;
            dialogRef = _this.dialogService.open(ModalCopyReportDialogComponent, {
                data: {
                    original_report_type: report_type
                }
            });
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        _this.apiService.get('reports/' + report_type + '/' + report_id + '/copy-report/' + dialogRef.componentInstance.report_type, {
                            report_type: dialogRef.componentInstance.report_type
                        }).then(function (data) {
                            if (typeof data !== 'undefined') {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data.link !== 'undefined') {
                                        var promises = [
                                            _this.syncBuildingParticulars(),
                                            _this.syncActions('warehouse'),
                                            _this.syncTypes('warehouse'),
                                            _this.syncMaterials('ladders'),
                                            _this.syncMaterials('tent'),
                                            _this.syncWarehouseQuestionnaires('warehouse'),
                                            _this.syncShortcomingQuestionnaires('ladders'),
                                            _this.syncShortcomingQuestionnaires('tent'),
                                            _this.syncReportsAttachments(),
                                            _this.syncReportsBuildings(),
                                            _this.syncReportsComments(),
                                            _this.syncReportsBuildingsReports()
                                        ];
                                        Promise.all(promises).then(function () {
                                            _this.previousUrlService.goTo(data.data.link);
                                            _this.snackbarService.success('Rapport kopiëren geslaagd!');
                                            _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsReinspections, report_id);
                                        });
                                    }
                                }
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report) {
                            if (typeof report !== 'undefined') {
                                var new_report_id_1 = moment().valueOf(), promises = [];
                                report['parent_report_id'] = report['id'];
                                report['id'] = new_report_id_1;
                                report['created'] = moment().valueOf();
                                report['status'] = 'open';
                                report['status_text'] = 'Openstaand';
                                report['report_nr'] = report['report_nr'] + '-Offline';
                                report['is_offline'] = 'yes';
                                delete report['updated'];
                                // save report
                                promises.push(new Promise(function (resolve0) {
                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReports, report).then(function () {
                                        var promises1 = [];
                                        report['type'] = report_type;
                                        report['indexedDBMethod'] = '1-create';
                                        promises1.push(new Promise(function (resolve1) {
                                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, report).then(function () {
                                                var promises2 = [];
                                                // copy report's comments
                                                promises2.push(new Promise(function (resolve2) {
                                                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsComments).then(function (commentsDB) {
                                                        var promises3 = [];
                                                        if (typeof commentsDB !== 'undefined') {
                                                            var comments = commentsDB.filter(function (data) { return data.report_id === report_id; });
                                                            comments.forEach(function (comment) {
                                                                var id = JSON.parse(JSON.stringify(comment.id));
                                                                comment['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
                                                                comment['created'] = report['created'];
                                                                comment['report_id'] = report['id'];
                                                                comment['report_type'] = report_type;
                                                                promises3.push(new Promise(function (resolve3) {
                                                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsComments, comment).then(function () {
                                                                        comment['copy_from_attachment_id'] = id;
                                                                        comment['copy_from_report_id'] = report_id;
                                                                        comment['indexedDBMethod'] = '0-copy';
                                                                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCommentsPush, comment).then(function () {
                                                                            resolve3();
                                                                        });
                                                                    });
                                                                }));
                                                            });
                                                        }
                                                        Promise.all(promises3).then(function () {
                                                            resolve2();
                                                        });
                                                    });
                                                }));
                                                // copy report's attachments
                                                promises2.push(new Promise(function (resolve2) {
                                                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachments).then(function (attachmentsDB) {
                                                        var promises3 = [];
                                                        if (typeof attachmentsDB !== 'undefined') {
                                                            var attachments = attachmentsDB.filter(function (data) { return data.report_id === report_id; });
                                                            attachments.forEach(function (attachment) {
                                                                var id = JSON.parse(JSON.stringify(attachment.id));
                                                                attachment['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
                                                                attachment['created'] = report['created'];
                                                                attachment['report_id'] = report['id'];
                                                                attachment['report_type'] = report_type;
                                                                delete attachment['download_url'];
                                                                promises3.push(new Promise(function (resolve3) {
                                                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachments, attachment).then(function () {
                                                                        attachment['copy_from_attachment_id'] = id;
                                                                        attachment['copy_from_report_id'] = report_id;
                                                                        attachment['indexedDBMethod'] = '0-copy';
                                                                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachmentsPush, attachment).then(function () {
                                                                            resolve3();
                                                                        });
                                                                    });
                                                                }));
                                                            });
                                                        }
                                                        Promise.all(promises3).then(function () {
                                                            resolve2();
                                                        });
                                                    });
                                                }));
                                                // if type == warehouse, copy questionnaire
                                                if (report_type === 'warehouse') {
                                                    promises2.push(new Promise(function (resolve2) {
                                                        _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, 'report_id', report_id).then(function (questionnaire) {
                                                            if (typeof questionnaire !== 'undefined') {
                                                                questionnaire['report_id'] = report['id'];
                                                                questionnaire['report_type'] = 'warehouse';
                                                                questionnaire['indexedDBMethod'] = '2-update';
                                                                questionnaire['report_type'] = report_type;
                                                                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, questionnaire).then(function () {
                                                                    resolve2();
                                                                });
                                                            }
                                                            else {
                                                                resolve2();
                                                            }
                                                        });
                                                    }));
                                                }
                                                // copy report's buildings
                                                promises2.push(new Promise(function (resolve2) {
                                                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (buildingsDB) {
                                                        if (typeof buildingsDB !== 'undefined') {
                                                            var buildings = buildingsDB.filter(function (data) { return data.report_id === report_id; }), promises3_1 = [];
                                                            buildings.forEach(function (building) {
                                                                var building_id = parseInt(JSON.parse(JSON.stringify(building['id'])), 10);
                                                                building['report_id'] = report['id'];
                                                                building['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
                                                                building['report_type'] = report_type;
                                                                if (typeof building.lib_particular_id !== 'undefined') {
                                                                    if (building.lib_particular_id.length === 0) {
                                                                        building['lib_particular_id'] = [];
                                                                    }
                                                                }
                                                                promises3_1.push(new Promise(function (resolve3) {
                                                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildings, building).then(function () {
                                                                        var promises4 = [];
                                                                        building['indexedDBMethod'] = '1-create';
                                                                        promises4.push(new Promise(function (resolve4) {
                                                                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, building).then(function () {
                                                                                resolve4();
                                                                            });
                                                                        }));
                                                                        // copy building reports
                                                                        promises4.push(new Promise(function (resolve4) {
                                                                            _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReports).then(function (buildingReportsDB) {
                                                                                var promises5 = [];
                                                                                if (typeof buildingReportsDB !== 'undefined') {
                                                                                    var buildingReports = buildingReportsDB.filter(function (data) { return data.report_id === report_id && data.building_id === building_id; });
                                                                                    buildingReports.forEach(function (buildingReport) {
                                                                                        // let id = parseInt(JSON.parse(JSON.stringify(buildingReport['id'])), 10);
                                                                                        buildingReport['report_id'] = report['id'];
                                                                                        buildingReport['building_id'] = building['id'];
                                                                                        buildingReport['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
                                                                                        buildingReport['report_type'] = report_type;
                                                                                        buildingReport['indexedDBMethod'] = '1-create';
                                                                                        delete buildingReport['photos'];
                                                                                        promises5.push(new Promise(function (resolve5) {
                                                                                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReports, buildingReport).then(function () {
                                                                                                var promises6 = [];
                                                                                                // copy ladder questionnaire
                                                                                                /*
                                                                                                                                                                                                if (report_type === 'ladders') {
                                                                                                                                                                                                    promises6.push(new Promise(resolve6 => {
                                                                                                                                                                                                        this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, 'building_report_id', id).then(ladderQuestionnaireDB => {
                                                                                                                                                                                                            let promises7 = [];

                                                                                                                                                                                                            if (typeof ladderQuestionnaireDB !== 'undefined') {
                                                                                                                                                                                                                delete ladderQuestionnaireDB['id'];
                                                                                                                                                                                                                ladderQuestionnaireDB['report_id'] = report['id'];
                                                                                                                                                                                                                ladderQuestionnaireDB['building_report_id'] = buildingReport['id'];
                                                                                                                                                                                                                ladderQuestionnaireDB['indexedDBMethod'] = '1-create';
                                                                                                                                                                                                                ladderQuestionnaireDB['report_type'] = report_type;

                                                                                                                                                                                                                // save ladder questionnaire
                                                                                                                                                                                                                promises7.push(new Promise(resolve7 => {
                                                                                                                                                                                                                    this.indexedDBService.database.add(this.indexedDBService.tableReportsLadderQuestionnaireAnswers, ladderQuestionnaireDB).then(() => {
                                                                                                                                                                                                                        resolve7();
                                                                                                                                                                                                                    });
                                                                                                                                                                                                                }));

                                                                                                                                                                                                                if (typeof ladderQuestionnaireDB.questions !== 'undefined') {
                                                                                                                                                                                                                    buildingReport['ladder_questions'] = {};
                                                                                                                                                                                                                    Object.keys(ladderQuestionnaireDB.questions).forEach(key => {
                                                                                                                                                                                                                        buildingReport['ladder_questions']['[' + ladderQuestionnaireDB['group_id'] + '][' + key + ']'] = ladderQuestionnaireDB.questions[key];
                                                                                                                                                                                                                    });
                                                                                                                                                                                                                }
                                                                                                                                                                                                                promises7.push(new Promise(resolve7 => {
                                                                                                                                                                                                                    this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, buildingReport).then(() => {
                                                                                                                                                                                                                        resolve7();
                                                                                                                                                                                                                    });
                                                                                                                                                                                                                }));
                                                                                                                                                                                                            }

                                                                                                                                                                                                            Promise.all(promises7).then(() => {
                                                                                                                                                                                                                resolve6();
                                                                                                                                                                                                            });
                                                                                                                                                                                                        });
                                                                                                                                                                                                    }));
                                                                                                                                                                                                } else {
                                                                                                */
                                                                                                promises6.push(new Promise(function (resolve6) {
                                                                                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, buildingReport).then(function () {
                                                                                                        resolve6();
                                                                                                    });
                                                                                                }));
                                                                                                // }
                                                                                                Promise.all(promises6).then(function () {
                                                                                                    resolve5();
                                                                                                });
                                                                                            }).catch(function (error) {
                                                                                                if (_this.debug) {
                                                                                                    console.log(error);
                                                                                                }
                                                                                            });
                                                                                        }));
                                                                                    });
                                                                                }
                                                                                Promise.all(promises5).then(function () {
                                                                                    resolve4();
                                                                                });
                                                                            });
                                                                        }));
                                                                        Promise.all(promises4).then(function () {
                                                                            resolve3();
                                                                        });
                                                                    });
                                                                }));
                                                            });
                                                            Promise.all(promises3_1).then(function () {
                                                                resolve2();
                                                            });
                                                        }
                                                        else {
                                                            resolve2();
                                                        }
                                                    });
                                                }));
                                                Promise.all(promises2).then(function () {
                                                    resolve1();
                                                });
                                            });
                                        }));
                                        Promise.all(promises1).then(function () {
                                            resolve0();
                                        });
                                    });
                                }));
                                Promise.all(promises).then(function () {
                                    _this.router.navigate(['/reports/' + report_type + '/' + new_report_id_1 + '/view']).then(function () {
                                        _this.snackbarService.success('Rapport kopiëren geslaagd!');
                                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsReinspections, report_id);
                                    });
                                }).catch(function () {
                                    if (_this.debug) {
                                        console.log('error, cleaning up ...');
                                    }
                                    _this.indexedDBService.database.delete(_this.indexedDBService.tableReports, report['id']);
                                    _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsPush, report['id']);
                                    _this.deleteReportComments(report['id']).then(function () {
                                    });
                                    _this.deleteReportAttachments(report['id']).then(function () {
                                    });
                                    _this.deleteReportBuildings(report['id']).then(function () {
                                    });
                                    _this.deleteReportBuildingsReports(report['id']).then(function () {
                                    });
                                    _this.deleteReportShortcomingQuestionnaireAnswers(report['id']).then(function () {
                                    });
                                    _this.deleteReportWarehouseQuestionnaireAnswers(report['id']).then(function () {
                                    });
                                });
                            }
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.syncInspectors = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/inspectors/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/inspectors', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableInspectors, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableInspectors,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () {
                                                        resolve(data.data);
                                                    })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncBuildingParticulars = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/building-particulars/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/building-particulars', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsBuildingParticulars, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: 'building_particulars',
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncCategories = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/elements/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/elements', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk((report_type === 'warehouse' ? _this.indexedDBService.tableReportsWarehouseCategories :
                                                    report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderCategories :
                                                        _this.indexedDBService.tableReportsTentCategories), data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: report_type === 'warehouse' ? _this.indexedDBService.tableReportsWarehouseCategories : report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderCategories : _this.indexedDBService.tableReportsTentCategories,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncSubcategories = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/elements/subelements/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/elements/subelements', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk((report_type === 'warehouse' ? _this.indexedDBService.tableReportsWarehouseSubcategories :
                                                    report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderSubcategories :
                                                        _this.indexedDBService.tableReportsTentSubcategories), data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: report_type === 'warehouse' ? _this.indexedDBService.tableReportsWarehouseSubcategories : report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderSubcategories : _this.indexedDBService.tableReportsTentSubcategories,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncTypes = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/elements/subelements/types/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/elements/subelements/types', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                var filtered_1 = [];
                                                data.data.forEach(function (type) {
                                                    type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                    filtered_1.push(type);
                                                });
                                                if (filtered_1.length) {
                                                    return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsWarehouseTypes, filtered_1)
                                                        .then(function () {
                                                        return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                            name: _this.indexedDBService.tableReportsWarehouseTypes,
                                                            updated: server_date.data.updated
                                                        })
                                                            .then(function () { return resolve(data.data); })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        });
                                                    })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                }
                                                else {
                                                    resolve(undefined);
                                                }
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncActions = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/elements/subelements/actions/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/elements/subelements/actions', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                var filtered_2 = [];
                                                data.data.forEach(function (action) {
                                                    action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                    filtered_2.push(action);
                                                });
                                                if (filtered_2.length) {
                                                    return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsWarehouseActions, filtered_2)
                                                        .then(function () {
                                                        return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                            name: _this.indexedDBService.tableReportsWarehouseActions,
                                                            updated: server_date.data.updated
                                                        })
                                                            .then(function () { return resolve(filtered_2); })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        });
                                                    })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                }
                                                else {
                                                    resolve(undefined);
                                                }
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncMaterials = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/materials/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/materials', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                var filtered_3 = [];
                                                data.data.forEach(function (material) {
                                                    material.material_name = material.material_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                    filtered_3.push(material);
                                                });
                                                if (filtered_3.length) {
                                                    return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsLadderMaterials, filtered_3)
                                                        .then(function () {
                                                        _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                            name: _this.indexedDBService.tableReportsLadderMaterials,
                                                            updated: server_date.data.updated
                                                        })
                                                            .then(function () { return resolve(filtered_3); })
                                                            .catch(function (error) {
                                                            if (_this.debug) {
                                                                console.log(error);
                                                            }
                                                            throw new Error(error);
                                                        });
                                                    })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                }
                                                else {
                                                    resolve(undefined);
                                                }
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncWarehouseQuestionnaires = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/questionnaire/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/questionnaire', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsWarehouseQuestionnaire, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsWarehouseQuestionnaire,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncWarehouseQuestionnaireAnswers = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/questionnaire/answers/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/questionnaire/answers', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncShortcomingQuestionnaires = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk((report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderQuestionnaire : _this.indexedDBService.tableReportsTentQuestionnaire), data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: (report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderQuestionnaire : _this.indexedDBService.tableReportsTentQuestionnaire),
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncShortcomingQuestionnaireAnswers = function (report_type) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires/answers/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/' + report_type + '/elements/subelements/questionnaires/answers', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk((report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderQuestionnaireAnswers : _this.indexedDBService.tableReportsTentQuestionnaireAnswers), data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: (report_type === 'ladders' ? _this.indexedDBService.tableReportsLadderQuestionnaireAnswers : _this.indexedDBService.tableReportsTentQuestionnaireAnswers),
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/sync', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReports, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReports,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReinspectionReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                return _this.indexedDBService.database.clear(_this.indexedDBService.tableReportsReinspections).then(function () {
                    _this.apiService.get('reports/reinspections', {
                        start: 0,
                        length: -1
                    }).then(function (data) {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsReinspections, data.data)
                                        .then(function () {
                                        resolve(data.data);
                                    })
                                        .catch(function (error) {
                                        if (_this.debug) {
                                            console.log(error);
                                        }
                                        throw new Error(error);
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }).catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }, function (error) {
                    console.log(error);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReportsAttachments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/warehouse/attachments/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/warehouse/attachments', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsAttachments, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsAttachments,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReportsComments = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/warehouse/comments/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/warehouse/comments', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsComments, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsAttachments,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReportsBuildings = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/warehouse/buildings/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/warehouse/buildings', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsBuildings, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsAttachments,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReportsBuildingsReports = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/warehouse/buildings/reports/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/warehouse/buildings/reports', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsBuildingsReports, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsAttachments,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncReportsBuildingsMapMarkers = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/warehouse/buildings-maps/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/warehouse/buildings-maps', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsBuildingsMaps, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsBuildingsMaps,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.cleanupAfterReportDeletion = function (reportsIndexes) {
        var _this = this;
        reportsIndexes.forEach(function (report_id) {
            _this.deleteReportAttachments(report_id).then(function () {
            });
            _this.deleteReportBuildings(report_id).then(function () {
            });
            _this.deleteReportBuildingsReports(report_id).then(function () {
            });
            _this.deleteReportComments(report_id).then(function () {
            });
            _this.deleteReportShortcomingQuestionnaireAnswers(report_id).then(function () {
            });
            _this.deleteReportWarehouseQuestionnaireAnswers(report_id).then(function () {
            });
            _this.indexedDBService.database.openCursorWithIndex(_this.indexedDBService.tableReportsSendToClient, 'report_id', function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    if (cursor.value.report_id === report_id) {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsSendToClient, cursor.value.id).then(function () {
                        }, function (error) {
                            if (_this.debug) {
                                console.log(error);
                            }
                            throw new Error(error);
                        });
                    }
                }
            }).then(function () {
            }, function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            });
        });
    };
    ReportsService.prototype.deleteReportAttachments = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachments)
                .then(function (reports_attachments) {
                var filtered = [];
                reports_attachments.forEach(function (report_attachment) {
                    if (report_attachment.report_id === report_id) {
                        filtered.push(report_attachment.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsAttachments, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachmentsPush)
                .then(function (reports_attachments) {
                var filtered = [];
                reports_attachments.forEach(function (report_attachment) {
                    if (report_attachment.report_id === report_id) {
                        filtered.push(report_attachment.entryId);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsAttachmentsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportBuildings = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings)
                .then(function (report_buildings) {
                var filtered = [];
                report_buildings.forEach(function (report_building) {
                    if (report_building.report_id === report_id) {
                        filtered.push(report_building.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildings, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsPush)
                .then(function (report_buildings) {
                var filtered = [];
                report_buildings.forEach(function (report_building) {
                    if (report_building.report_id === report_id) {
                        filtered.push(report_building.entryId);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportBuildingsReports = function (report_id, building_id) {
        var _this = this;
        if (building_id === void 0) { building_id = null; }
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReports)
                .then(function (reports_buildings_reports) {
                var filtered = [];
                reports_buildings_reports.forEach(function (report_building_report) {
                    if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
                        filtered.push(report_building_report.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsReports, filtered)
                        .then(function () { return _this.deleteReportShortcomingQuestionnaireAnswers(report_id, filtered); })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReportsPush)
                .then(function (reports_buildings_reports) {
                var filtered = [];
                reports_buildings_reports.forEach(function (report_building_report) {
                    if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
                        filtered.push(report_building_report.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsReportsPush, filtered)
                        .then(function () { return _this.deleteReportShortcomingQuestionnaireAnswers(report_id, filtered); })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReportsPhotos)
                .then(function (reports_buildings_reports_photos) {
                var filtered = [];
                reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                    if (building_id === null && report_building_report_photo.report_id === report_id || building_id !== null && report_building_report_photo.id === building_id && report_building_report_photo.report_id === report_id) {
                        filtered.push(report_building_report_photo.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsReportsPhotos, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportComments = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsComments)
                .then(function (reports_comments) {
                var filtered = [];
                reports_comments.forEach(function (report_comment) {
                    if (report_comment.report_id === report_id) {
                        filtered.push(report_comment.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsComments, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsCommentsPush)
                .then(function (reports_comments) {
                var filtered = [];
                reports_comments.forEach(function (report_comment) {
                    if (report_comment.report_id === report_id) {
                        filtered.push(report_comment.entryId);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsCommentsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            })
                .catch(function (error) {
                if (_this.debug) {
                    console.log(error);
                }
                throw new Error(error);
            }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportShortcomingQuestionnaireAnswers = function (report_id, building_report_ids) {
        var _this = this;
        if (building_report_ids === void 0) { building_report_ids = null; }
        var promises = [];
        promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsLadderQuestionnaireAnswers)
            .then(function (reports_ladder_questionnaire_answers) {
            var filtered = [];
            reports_ladder_questionnaire_answers.forEach(function (report_ladder_questionnaire_answer) {
                building_report_ids.forEach(function (building_report_id) {
                    if (building_report_id === null && report_ladder_questionnaire_answer.report_id === report_id || building_report_id !== null && report_ladder_questionnaire_answer.building_report_id === building_report_id && report_ladder_questionnaire_answer.report_id === report_id) {
                        filtered.push(report_ladder_questionnaire_answer.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsLadderQuestionnaireAnswers, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            });
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        }));
        promises.push(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsTentQuestionnaireAnswers)
            .then(function (reports_tent_questionnaire_answers) {
            var filtered = [];
            reports_tent_questionnaire_answers.forEach(function (report_tent_questionnaire_answer) {
                building_report_ids.forEach(function (building_report_id) {
                    if (building_report_id === null && report_tent_questionnaire_answer.report_id === report_id || building_report_id !== null && report_tent_questionnaire_answer.building_report_id === building_report_id && report_tent_questionnaire_answer.report_id === report_id) {
                        filtered.push(report_tent_questionnaire_answer.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsTentQuestionnaireAnswers, filtered)
                        .then(function () { return true; })
                        .catch(function (error) {
                        if (_this.debug) {
                            console.log(error);
                        }
                        throw new Error(error);
                    });
                }
                return true;
            });
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        }));
        return Promise.all(promises).then(function () {
        });
    };
    ReportsService.prototype.deleteReportWarehouseQuestionnaireAnswers = function (report_id) {
        var _this = this;
        return new Promise(this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers)
            .then(function (reports_questionnaire_answers) {
            var filtered = [];
            reports_questionnaire_answers.forEach(function (report_questionnaire_answer) {
                if (report_questionnaire_answer.report_id === report_id) {
                    filtered.push(report_questionnaire_answer.id);
                }
            });
            if (filtered.length) {
                return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsWarehouseQuestionnaireAnswers, filtered)
                    .then(function () { return true; })
                    .catch(function (error) {
                    if (_this.debug) {
                        console.log(error);
                    }
                    throw new Error(error);
                });
            }
            return true;
        })
            .catch(function (error) {
            if (_this.debug) {
                console.log(error);
            }
            throw new Error(error);
        }));
    };
    ReportsService.prototype.showReportBuildingMap = function (building_map_url, readonly, markers) {
        var _this = this;
        if (readonly === void 0) { readonly = true; }
        if (markers === void 0) { markers = []; }
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(BuildingMapWidgetComponent, {
                data: {
                    readonly: readonly,
                    buildingMapUrl: building_map_url,
                    markers: markers,
                }
            });
            dialogRef.afterClosed().subscribe(function () {
                resolve(dialogRef.componentInstance.markers);
            });
        });
    };
    return ReportsService;
}());
export { ReportsService };
