var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/Rx';
import { LoadingOverlayService } from './loading-overlay.service';
import { ApiService } from '../_interceptors/api.service';
import { SnackbarService } from './snackbar.service';
import { PreviousUrlService } from './previous-url.service';
import { MatDialog } from '@angular/material/dialog';
import { SimpleModalDialogComponent } from '../core/simple-modal/simple-modal.component';
import { IndexedDBService } from './indexeddb.service';
import { AuthenticationService } from './authentication.service';
import * as moment from 'moment';
import { environment } from '../../environments/environment';
var InspectorsService = /** @class */ (function () {
    function InspectorsService(apiService, dialogService, overlayService, snackbarService, previousUrlService, indexedDBService, authenticationService, router) {
        this.apiService = apiService;
        this.dialogService = dialogService;
        this.overlayService = overlayService;
        this.snackbarService = snackbarService;
        this.previousUrlService = previousUrlService;
        this.indexedDBService = indexedDBService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.errors = new BehaviorSubject(null);
        this.debug = !environment.production;
    }
    InspectorsService.prototype.getInspectorsCalibrations = function (formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_1 = [], collectionData_1 = [], startIndex_1 = 0, endIndex_1 = -1, searchString_1 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_1 = formData['start'];
                            endIndex_1 = formData['length'];
                            if (endIndex_1 !== -1) {
                                endIndex_1 += startIndex_1;
                            }
                        }
                        if (typeof formData['columns[3][search][value]'] !== 'undefined') {
                            if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
                                searchString_1 = formData['columns[3][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableInspectorsCalibrations).then(function (calibrationsDB) {
                        if (typeof calibrationsDB !== 'undefined') {
                            calibrationsDB.sort(function (a, b) { return a.next_calibration_date > b.next_calibration_date ? -1 : 1; });
                            if (searchString_1 !== null) {
                                collectionData_1 = calibrationsDB.filter(function (calibrations) { return calibrations.device_id.toLowerCase().indexOf(searchString_1) !== -1; });
                            }
                            else {
                                collectionData_1 = calibrationsDB;
                            }
                            if (endIndex_1 !== -1) {
                                resultsData_1 = collectionData_1.slice(startIndex_1, endIndex_1);
                            }
                            else {
                                resultsData_1 = collectionData_1.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_1.length,
                                data: resultsData_1
                            });
                        }
                        else {
                            _this.syncInspectorsCalibrations().then(function (calibrations) {
                                if (typeof calibrations !== 'undefined') {
                                    if (searchString_1 !== null) {
                                        collectionData_1 = calibrations.filter(function (calibrations2) { return calibrations2.device_id.toLowerCase().indexOf(searchString_1) !== -1; });
                                    }
                                    else {
                                        collectionData_1 = calibrations;
                                    }
                                    if (endIndex_1 !== -1) {
                                        resultsData_1 = collectionData_1.slice(startIndex_1, endIndex_1);
                                    }
                                    else {
                                        resultsData_1 = collectionData_1.slice();
                                    }
                                    resolve({
                                        recordsTotal: collectionData_1.length,
                                        data: resultsData_1
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectorsCalibrations().then(function (calibrations) {
                            if (typeof calibrations !== 'undefined') {
                                if (searchString_1 !== null) {
                                    collectionData_1 = calibrations.filter(function (calibrations2) { return calibrations2.device_id.toLowerCase().indexOf(searchString_1) !== -1; });
                                }
                                else {
                                    collectionData_1 = calibrations;
                                }
                                if (endIndex_1 !== -1) {
                                    resultsData_1 = collectionData_1.slice(startIndex_1, endIndex_1);
                                }
                                else {
                                    resultsData_1 = collectionData_1.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData_1.length,
                                    data: resultsData_1
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    InspectorsService.prototype.getInspectorCalibration = function (calibration_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_2 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableInspectorsCalibrations, calibration_id).then(function (calibration) {
                        if (typeof calibration !== 'undefined') {
                            resolve(calibration);
                        }
                        else {
                            _this.syncInspectorsCalibrations().then(function (calibrations) {
                                if (typeof calibrations !== 'undefined') {
                                    resultsData_2 = calibrations.filter(function (calibrations2) { return calibrations2.id === calibration_id; });
                                    if (resultsData_2.length) {
                                        resolve(resultsData_2[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectorsCalibrations().then(function (calibrations) {
                            if (typeof calibrations !== 'undefined') {
                                resultsData_2 = calibrations.filter(function (calibrations2) { return calibrations2.id === calibration_id; });
                                if (resultsData_2.length) {
                                    resolve(resultsData_2[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    InspectorsService.prototype.createInspectorCalibration = function (formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('calibration', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Kalibratie opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsCalibrations, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/inspectors/calibrations']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationSubscription = this.authenticationService.user.first().subscribe(function (user) {
                formData['date_calibrated'] = moment(formData['date_calibrated'], 'DD-MM-YYYY').valueOf();
                formData['next_calibration_date'] = moment(formData['next_calibration_date'], 'DD-MM-YYYY').valueOf();
                formData['inspector'] = user.display_name;
                formData['id'] = moment().valueOf();
                delete formData['date_calibrated_input'];
                delete formData['next_calibration_date_input'];
                _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsCalibrations, formData).then(function () {
                    _this.snackbarService.success('Kalibratie opgeslagen!');
                    formData['indexedDBMethod'] = '1-create';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsCalibrationsPush, formData).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    _this.router.navigate(['/inspectors/calibrations']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            });
            _authenticationSubscription.unsubscribe();
        }
    };
    InspectorsService.prototype.updateInspectorCalibration = function (calibration_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('calibration/' + calibration_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Kalibratie opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableInspectorsCalibrations, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/inspectors/calibrations']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableInspectorsCalibrations, 'id', calibration_id).then(function (calibration) {
                delete formData['date_calibrated_input'];
                delete formData['next_calibration_date_input'];
                var newCalibration = __assign({}, calibration, formData);
                newCalibration['date_calibrated'] = moment(formData['date_calibrated'], 'DD-MM-YYYY').valueOf();
                newCalibration['next_calibration_date'] = moment(formData['next_calibration_date'], 'DD-MM-YYYY').valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableInspectorsCalibrations, newCalibration).then(function () {
                    _this.snackbarService.success('Kalibratie opgeslagen!');
                    newCalibration['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsCalibrationsPush, newCalibration).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    _this.router.navigate(['/inspectors/calibrations']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            });
        }
    };
    InspectorsService.prototype.deleteInspectorCalibration = function (calibration_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze kalibratie wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('calibration/' + calibration_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Kalibratie verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableInspectorsCalibrations, calibration_id).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableInspectorsCalibrations, calibration_id).then(function () {
                            _this.snackbarService.success('Kalibratie verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsCalibrationsPush, {
                                id: calibration_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                            }, function (error) {
                                console.log(error);
                                throw new Error(error);
                            });
                            resolve(true);
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            resolve(false);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    InspectorsService.prototype.getInspectorsInventories = function (formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_3 = [], collectionData_2 = [], startIndex_2 = 0, endIndex_2 = -1, searchString_2 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_2 = formData['start'];
                            endIndex_2 = formData['length'];
                            if (endIndex_2 !== -1) {
                                endIndex_2 += startIndex_2;
                            }
                        }
                        if (typeof formData['columns[1][search][value]'] !== 'undefined') {
                            if (formData['columns[1][search][value]'] !== null && formData['columns[1][search][value]'] !== '') {
                                searchString_2 = formData['columns[1][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableInspectorsInventory).then(function (inventoriesDB) {
                        if (typeof inventoriesDB !== 'undefined') {
                            inventoriesDB.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            if (searchString_2 !== null) {
                                collectionData_2 = inventoriesDB.filter(function (inventories) { return inventories.original_filename.toLowerCase().indexOf(searchString_2) !== -1; });
                            }
                            else {
                                collectionData_2 = inventoriesDB;
                            }
                            if (endIndex_2 !== -1) {
                                resultsData_3 = collectionData_2.slice(startIndex_2, endIndex_2);
                            }
                            else {
                                resultsData_3 = collectionData_2.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_2.length,
                                data: resultsData_3
                            });
                        }
                        else {
                            _this.syncInspectorsInventories().then(function (inventories) {
                                if (typeof inventories !== 'undefined') {
                                    if (searchString_2 !== null) {
                                        collectionData_2 = inventories.filter(function (inventories2) { return inventories2.original_filename.toLowerCase().indexOf(searchString_2) !== -1; });
                                    }
                                    else {
                                        collectionData_2 = inventories;
                                    }
                                    if (endIndex_2 !== -1) {
                                        resultsData_3 = collectionData_2.slice(startIndex_2, endIndex_2);
                                    }
                                    else {
                                        resultsData_3 = collectionData_2.slice();
                                    }
                                    resolve({
                                        recordsTotal: collectionData_2.length,
                                        data: resultsData_3
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectorsInventories().then(function (inventories) {
                            if (typeof inventories !== 'undefined') {
                                if (searchString_2 !== null) {
                                    collectionData_2 = inventories.filter(function (inventories2) { return inventories2.original_filename.toLowerCase().indexOf(searchString_2) !== -1; });
                                }
                                else {
                                    collectionData_2 = inventories;
                                }
                                if (endIndex_2 !== -1) {
                                    resultsData_3 = collectionData_2.slice(startIndex_2, endIndex_2);
                                }
                                else {
                                    resultsData_3 = collectionData_2.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData_2.length,
                                    data: resultsData_3
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    InspectorsService.prototype.getInspectorInventory = function (inventory_id) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_4 = [];
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableInspectorsInventory, inventory_id).then(function (inventory) {
                        if (typeof inventory !== 'undefined') {
                            resolve(inventory);
                        }
                        else {
                            _this.syncInspectorsInventories().then(function (inventories) {
                                if (typeof inventories !== 'undefined') {
                                    resultsData_4 = inventories.filter(function (inventories2) { return inventories2.id === inventory_id; });
                                    if (resultsData_4.length) {
                                        resolve(resultsData_4[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectorsInventories().then(function (inventories) {
                            if (typeof inventories !== 'undefined') {
                                resultsData_4 = inventories.filter(function (inventories2) { return inventories2.id === inventory_id; });
                                if (resultsData_4.length) {
                                    resolve(resultsData_4[0]);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    InspectorsService.prototype.createInspectorInventory = function (formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('inventory', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Inventaris opgeslagen!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsInventory, data.data).then(function () {
                        }, function (error) {
                            console.log(error);
                            throw new Error(error);
                        });
                    }
                    _this.router.navigate(['/inspectors/inventories']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            var _authenticationSubscription = this.authenticationService.user.first().subscribe(function (user) {
                formData['indexedDBMethod'] = '1-create';
                formData['created'] = moment().valueOf();
                formData['user_id'] = user.id;
                formData['id'] = moment().valueOf();
                var newFormData = {
                    id: formData['id'],
                    user_id: formData['user_id'],
                    created: formData['created'],
                    filename: formData.filename._files[0].name,
                    original_filename: formData.filename._files[0].name
                };
                _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsInventory, newFormData).then(function () {
                    _this.snackbarService.success('Inventaris opgeslagen!');
                    formData['original_filename'] = newFormData['filename'];
                    _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsInventoryPush, formData).then(function () {
                    }, function (error) {
                        console.log(error);
                        throw new Error(error);
                    });
                    _this.router.navigate(['/inspectors/inventories']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                    throw new Error(error);
                });
            });
            _authenticationSubscription.unsubscribe();
        }
    };
    InspectorsService.prototype.deleteInspectorInventory = function (inventory_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze inventaris wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('inventory/' + inventory_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Inventaris verwijderd.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableInspectorsInventory, inventory_id).then(function () {
                                }, function (error) {
                                    console.log(error);
                                    throw new Error(error);
                                });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableInspectorsInventory, inventory_id).then(function () {
                            _this.snackbarService.success('Inventaris verwijderd.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableInspectorsInventoryPush, {
                                id: inventory_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                            }, function (error) {
                                console.log(error);
                                throw new Error(error);
                            });
                            resolve(true);
                        }, function (error) {
                            _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                            resolve(false);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    InspectorsService.prototype.sendInspectorInventoryToClient = function (inventory_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('inventory/' + inventory_id + '/send-to-client', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Inventaris document verstuurd naar klant.');
                    if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                        _this.previousUrlService.goToPreviousUrl();
                    }
                    else {
                        _this.router.navigate(['/inspectors/inventories/']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                }
            });
        }
        else {
            formData['inventory_id'] = inventory_id;
            delete formData['client_filter_input'];
            this.indexedDBService.database.add(this.indexedDBService.tableInspectorsInventorySendToClient, formData).then(function () {
                _this.snackbarService.success('Inventaris document wordt verstuurd naar de klant indien er weer internet is.');
                if (_this.previousUrlService.getPreviousUrl() !== '' && _this.previousUrlService.getPreviousUrl() !== '/') {
                    _this.previousUrlService.goToPreviousUrl();
                }
                else {
                    _this.router.navigate(['/inspectors/inventories/']).then(function () {
                    });
                }
            }, function (error) {
                console.log(error);
                throw new Error(error);
            });
        }
    };
    InspectorsService.prototype.getInspectorsInspections = function (formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event === true) {
                    var collectionData_3 = [], resultsData_5 = [], startIndex_3 = 0, endIndex_3 = -1, searchString_3 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_3 = formData['start'];
                            endIndex_3 = formData['length'];
                            if (endIndex_3 !== -1) {
                                endIndex_3 += startIndex_3;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_3 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableInspectorsInspections).then(function (inspectionsDB) {
                        if (typeof inspectionsDB !== 'undefined') {
                            inspectionsDB.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            if (searchString_3 !== null) {
                                collectionData_3 = inspectionsDB.filter(function (inspections) { return inspections.company_name.toLowerCase().indexOf(searchString_3) !== -1; });
                            }
                            else {
                                collectionData_3 = inspectionsDB;
                            }
                            if (endIndex_3 !== -1) {
                                resultsData_5 = collectionData_3.slice(startIndex_3, endIndex_3);
                            }
                            else {
                                resultsData_5 = collectionData_3.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_3.length,
                                data: resultsData_5
                            });
                        }
                        else {
                            _this.syncInspectorsInspections().then(function (inspections) {
                                if (typeof inspections !== 'undefined') {
                                    if (searchString_3 !== null) {
                                        collectionData_3 = inspections.filter(function (inspections2) { return inspections2.company_name.toLowerCase().indexOf(searchString_3) !== -1; });
                                    }
                                    else {
                                        collectionData_3 = inspections;
                                    }
                                    if (endIndex_3 !== -1) {
                                        resultsData_5 = collectionData_3.slice(startIndex_3, endIndex_3);
                                    }
                                    else {
                                        resultsData_5 = collectionData_3.slice();
                                    }
                                    resolve({
                                        recordsTotal: collectionData_3.length,
                                        data: resultsData_5
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                reject();
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncInspectorsInspections().then(function (inspections) {
                            if (typeof inspections !== 'undefined') {
                                if (searchString_3 !== null) {
                                    collectionData_3 = inspections.filter(function (inspections2) { return inspections2.company_name.toLowerCase().indexOf(searchString_3) !== -1; });
                                }
                                else {
                                    collectionData_3 = inspections;
                                }
                                if (endIndex_3 !== -1) {
                                    resultsData_5 = collectionData_3.slice(startIndex_3, endIndex_3);
                                }
                                else {
                                    resultsData_5 = collectionData_3.slice();
                                }
                                resolve({
                                    recordsTotal: collectionData_3.length,
                                    data: resultsData_5
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            reject();
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    InspectorsService.prototype.syncInspectorsCalibrations = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('calibration/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('calibration', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableInspectorsCalibrations, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableInspectorsCalibrations,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    InspectorsService.prototype.syncInspectorsInventories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('inventory/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('inventory', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableInspectorsInventory, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableInspectorsInventory,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                })
                                                    .catch(function (error) {
                                                    if (_this.debug) {
                                                        console.log(error);
                                                    }
                                                    throw new Error(error);
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    InspectorsService.prototype.syncInspectorsInspections = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('inspections/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('inspections', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableInspectorsInspections, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableInspectorsInspections,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) {
                                                        if (_this.debug) {
                                                            console.log(error);
                                                        }
                                                        throw new Error(error);
                                                    });
                                                });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) {
                                    if (_this.debug) {
                                        console.log(error);
                                    }
                                    throw new Error(error);
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    return InspectorsService;
}());
export { InspectorsService };
