import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ReportsService } from '../../../_services/reports.service';
import { ClientsService } from '../../../_services/clients.service';
import { ApiService } from '../../../_interceptors/api.service';
import { AuthenticationService } from '../../../_services/authentication.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
import { MultipleEmailsValidator } from '../../../_validators/multiple-emails';
var ɵ0 = APP_DATE_FORMATS;
var ReportsSendToClientComponent = /** @class */ (function () {
    function ReportsSendToClientComponent(reportsService, authenticationService, apiService, clientsService, formsService, activatedRoute, formBuilder, previousUrlService, pageTitleService) {
        this.reportsService = reportsService;
        this.authenticationService = authenticationService;
        this.apiService = apiService;
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.report_id = null;
        this.report_type = null;
        this.report = null;
        this.client = null;
        this.contactPersons = [];
        this.dataLoaded = false;
        this.missingData = [];
        this.reportEditable = false;
        this.days = [];
        this.hours = [];
        this.minutes = [];
        this.reportSendToClientForm = this.formBuilder.group({
            inspection_duration_day: ['0', Validators.required],
            inspection_duration_hour: ['0', Validators.required],
            inspection_duration_minute: ['0', Validators.required],
            contact_persons_ids: this.formBuilder.array([]),
            custom_contacts: ['', MultipleEmailsValidator()],
            include_certificate: ['1'],
            message: ['']
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
        this._authenticationServiceSubscription = null;
        for (var i = 0; i <= 31; i++) {
            this.days.push(i);
        }
        for (var i = 0; i <= 24; i++) {
            this.hours.push(i);
        }
        for (var i = 0; i <= 60; i = i + 10) {
            this.minutes.push(i);
        }
    }
    ReportsSendToClientComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.missingData.length) {
                _this.missingData = [];
                _this.loadData();
            }
        });
        this.formsService.form = this.reportSendToClientForm;
        this._errorsSubscription = this.reportsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.report_type = params['report_type'];
            _this.report_id = parseInt(params['report_id'], 10);
            _this.reportsService.getReport(_this.report_type, _this.report_id).then(function (report) {
                _this._authenticationServiceSubscription = _this.authenticationService.user.first().subscribe(function (user) {
                    if (typeof report !== 'undefined' && typeof user !== 'undefined') {
                        if (typeof report.edit_lock !== 'undefined' && typeof report.edit_lock_user_id !== 'undefined') {
                            if (report.edit_lock === true && (report.edit_lock_user_id === '' ||
                                report.edit_lock_user_id === null ||
                                report.edit_lock_user_id === 0 ||
                                report.edit_lock_user_id === user.id ||
                                user.type === 'kvvm-admin')) {
                                _this.reportEditable = true;
                            }
                        }
                        if (typeof report.parent_report_id === 'undefined' || typeof report.parent_report_id !== 'undefined' && report.parent_report_id !== 0) {
                            _this.reportSendToClientForm.get('include_certificate').setValue('0');
                        }
                        _this.pageTitleService.setTitle(report.report_nr + ' | Verstuur naar klant | ' + environment.webappName);
                        _this.pageTitleService.setPageTitle(report.report_nr);
                    }
                });
            });
            _this.previousUrlService.setPreviousUrlIfNull('/reports/' + _this.report_type + '/' + _this.report_id + '/view');
            _this.loadData();
        });
    };
    ReportsSendToClientComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
        if (this._authenticationServiceSubscription !== null) {
            this._authenticationServiceSubscription.unsubscribe();
        }
    };
    ReportsSendToClientComponent.prototype.fixAngularScrewUpAndInsertActualValue = function (event, index) {
        if (event.checked) {
            this.reportSendToClientForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
        }
    };
    ReportsSendToClientComponent.prototype.loadData = function () {
        var _this = this;
        this.reportsService.getReport(this.report_type, this.report_id).then(function (details) {
            if (typeof details !== 'undefined') {
                _this.report = details;
                if (typeof _this.report.client_id !== 'undefined') {
                    _this.clientsService.getClient(_this.report.client_id).then(function (client) {
                        if (typeof client !== 'undefined') {
                            _this.client = client;
                        }
                        else {
                            _this.missingData.push('Klant');
                        }
                    });
                    _this.clientsService.getContactPersons(_this.report.client_id).then(function (contactPersons) {
                        if (typeof contactPersons !== 'undefined') {
                            if (typeof contactPersons.data !== 'undefined') {
                                _this.contactPersons = contactPersons.data;
                                contactPersons.data.forEach(function (contact_person) {
                                    var control = _this.reportSendToClientForm.controls.contact_persons_ids;
                                    control.push(new FormControl({ value: contact_person.id, disabled: !contact_person.email }));
                                });
                            }
                            else {
                                _this.missingData.push('Contactpersoon');
                            }
                        }
                        else {
                            _this.missingData.push('Contactpersoon');
                        }
                    });
                }
                _this.dataLoaded = true;
            }
            else {
                _this.missingData.push('Rapportage');
            }
        });
    };
    return ReportsSendToClientComponent;
}());
export { ReportsSendToClientComponent };
export { ɵ0 };
