var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnInit } from '@angular/core';
import { ClientsService } from '../../../_services/clients.service';
import { GeneralService } from '../../../_services/general.service';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { ApiService } from '../../../_interceptors/api.service';
import { AuthenticationService } from '../../../_services/authentication.service';
var ClientsListComponent = /** @class */ (function () {
    function ClientsListComponent(clientsService, previousUrlService, apiService, authenticationService, generalService) {
        this.clientsService = clientsService;
        this.previousUrlService = previousUrlService;
        this.apiService = apiService;
        this.authenticationService = authenticationService;
        this.generalService = generalService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.clients = [];
    }
    ClientsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && !_this.clients.length) {
                _this.currentPage = 0;
                _this.getClients(false);
            }
        });
        this.getClients(false);
    };
    ClientsListComponent.prototype.getClients = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.clientsService.getClients({
                start: (this.currentPage - 1) * this.generalService.itemsPerPage,
                length: this.generalService.itemsPerPage,
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data.recordsTotal !== 'undefined') {
                    _this.totalItems = data.recordsTotal;
                }
                if (append_data === true) {
                    (_a = _this.clients).push.apply(_a, __spread(data.data));
                }
                else {
                    _this.clients = data.data;
                }
                _this.isLoading = false;
            });
        }
    };
    ClientsListComponent.prototype.deleteClient = function (client_id) {
        var _this = this;
        this.clientsService.deleteClient(client_id).then(function (status) {
            if (status === true) {
                var index = _this.clients.findIndex(function (data) { return data.id === client_id; });
                if (index !== -1) {
                    _this.clients.splice(index, 1);
                }
            }
        });
    };
    ClientsListComponent.prototype.search = function ($event) {
        this.searchString = $event;
        this.currentPage = 0;
        this.getClients(false);
    };
    return ClientsListComponent;
}());
export { ClientsListComponent };
