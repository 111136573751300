var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from '../../../../../_services/general.service';
import { PreviousUrlService } from '../../../../../_services/previous-url.service';
import { ReportsService } from '../../../../../_services/reports.service';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../../../../_interceptors/api.service';
import { AuthenticationService } from '../../../../../_services/authentication.service';
import { PageTitleService } from '../../../../../_services/page-title.service';
import { environment } from '../../../../../../environments/environment';
import { MediaReplayService } from '../../../../../core/sidenav/mediareplay/media-replay.service';
import { MatTable } from '@angular/material/table';
import * as moment from 'moment';
import { ClientsService } from '../../../../../_services/clients.service';
var ReportsBuildingsReportsListComponent = /** @class */ (function () {
    function ReportsBuildingsReportsListComponent(reportsService, mediaReplayService, apiService, activatedRoute, authenticationService, previousUrlService, pageTitleService, clientsService, generalService) {
        this.reportsService = reportsService;
        this.mediaReplayService = mediaReplayService;
        this.apiService = apiService;
        this.activatedRoute = activatedRoute;
        this.authenticationService = authenticationService;
        this.previousUrlService = previousUrlService;
        this.pageTitleService = pageTitleService;
        this.clientsService = clientsService;
        this.generalService = generalService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.report_type = null;
        this.report_id = null;
        this.building_id = null;
        this.report_status = '/open';
        this.buildingReports = [];
        this.reportEditable = false;
        this.user = null;
        this.show_checkboxes = true;
        this.hasBuildingMap = false;
        this.buildingMapUrl = null;
        this.mapMarkers = [];
        this._activatedRouteSubscription = null;
        this._authenticationSubscription = null;
        this._mediaSubscription = null;
        // table on tablet
        this.isTablet = false;
        this.displayedColumns = ['number', 'location_nr', 'measure_depth', 'measure_height', 'color_code', 'category_name', 'subcategory_name', 'shortcoming_types', 'shortcoming_actions', 'commands'];
        this.smallWidget = false;
    }
    ReportsBuildingsReportsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._mediaSubscription = this.mediaReplayService.media$.subscribe(function (change) {
            _this.isTablet = (change.mqAlias === 'md' || change.mqAlias === 'lg');
        });
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && !_this.buildingReports.length) {
                _this.currentPage = 0;
                _this.getReportsBuildingReports(false);
            }
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.report_type = params['report_type'];
            _this.report_id = parseInt(params['report_id'], 10);
            _this.building_id = parseInt(params['building_id'], 10);
            _this.reportsService.getBuilding(_this.report_type, _this.report_id, _this.building_id).then(function (data) {
                if (typeof data !== 'undefined') {
                    if (typeof data.building_map_id !== 'undefined') {
                        if (data.building_map_id !== null) {
                            _this.reportsService.getReport(_this.report_type, _this.report_id).then(function (report) {
                                if (typeof report !== 'undefined') {
                                    _this.clientsService.getBuildingMap(report.client_id, data.building_map_id).then(function (buildingMap) {
                                        if (typeof buildingMap !== 'undefined') {
                                            _this.hasBuildingMap = true;
                                            _this.buildingMapUrl = buildingMap.map_url;
                                        }
                                    });
                                    _this.reportsService.getBuildingMapMarkers(_this.report_id, _this.building_id).then(function (markers) {
                                        _this.mapMarkers = markers;
                                    });
                                }
                            });
                        }
                    }
                    if (_this.smallWidget === false) {
                        if (_this.report_type === 'warehouse') {
                            _this.pageTitleService.setTitle(data.name + ' | Gebouwen | ' + environment.webappName);
                        }
                        else {
                            _this.pageTitleService.setTitle(data.name + ' | Gebouwen - Trappen | ' + environment.webappName);
                        }
                        _this.pageTitleService.setPageTitle(data.name);
                    }
                    if (typeof data.edit_lock !== 'undefined' && typeof data.edit_lock_user_id !== 'undefined') {
                        if (data.edit_lock === true && (data.edit_lock_user_id === '' ||
                            data.edit_lock_user_id === null ||
                            data.edit_lock_user_id === 0 ||
                            data.edit_lock_user_id === _this.user.id ||
                            _this.user.type === 'kvvm-admin')) {
                            _this.reportEditable = true;
                        }
                    }
                }
            });
            _this._authenticationSubscription = _this.authenticationService.user.first().subscribe(function (user) {
                _this.user = user;
                _this.currentPage = 0;
                _this.getReportsBuildingReports(false);
            });
        });
    };
    ReportsBuildingsReportsListComponent.prototype.ngOnDestroy = function () {
        if (this._mediaSubscription !== null) {
            this._mediaSubscription.unsubscribe();
        }
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._authenticationSubscription !== null) {
            this._authenticationSubscription.unsubscribe();
        }
    };
    ReportsBuildingsReportsListComponent.prototype.getReportsBuildingReports = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.reportsService.getBuildingReports(this.report_type, this.report_id, this.building_id, {
                start: (this.currentPage - 1) * this.generalService.itemsPerPage,
                length: this.generalService.itemsPerPage,
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        _this.totalItems = data.recordsTotal;
                    }
                    if (append_data === true) {
                        (_a = _this.buildingReports).push.apply(_a, __spread(_this.processReportData(data.data)));
                        if (_this.isTablet && _this.report_type === 'warehouse') {
                            _this.table.renderRows();
                        }
                    }
                    else {
                        _this.buildingReports = _this.processReportData(data.data);
                        if (_this.isTablet && _this.report_type === 'warehouse') {
                            _this.table.renderRows();
                        }
                    }
                }
                _this.isLoading = false;
            });
        }
    };
    ReportsBuildingsReportsListComponent.prototype.processReportData = function (reportData) {
        var _this = this;
        reportData.forEach(function (report) {
            report.shortcoming_types = [];
            report.shortcoming_actions = [];
            _this.reportsService.getShortcomingTypes(_this.report_type, report.lib_warehouse_element_id, report.lib_warehouse_subelement_id).then(function (data3) {
                if (typeof data3 !== 'undefined') {
                    if (typeof data3.data !== 'undefined') {
                        data3.data.forEach(function (data4) {
                            if (data4.active === true) {
                                if (report.warehouse_type_ids.indexOf(data4.id) !== -1) {
                                    var type = {
                                        id: data4.id,
                                        category_id: data4.category_id,
                                        subcategory_id: data4.subcategories,
                                        type_name: data4.type_name,
                                        amounts: data4.amounts,
                                        active: true,
                                        result: null
                                    };
                                    if (data4.amounts === true) {
                                        if (typeof report.warehouse_type_field_value !== 'undefined') {
                                            if (typeof report.warehouse_type_field_value[data4.id] !== 'undefined') {
                                                type.result = report.warehouse_type_field_value[data4.id];
                                            }
                                        }
                                    }
                                    report.shortcoming_types.push(type);
                                }
                            }
                        });
                        _this.reportsService.getShortcomingActions(_this.report_type, report.lib_warehouse_element_id, report.lib_warehouse_subelement_id).then(function (data4) {
                            if (typeof data4 !== 'undefined') {
                                if (typeof data4.data !== 'undefined') {
                                    data4.data.forEach(function (data5) {
                                        if (typeof report.warehouse_action_ids !== 'undefined') {
                                            if (report.warehouse_action_ids !== null) {
                                                if (report.warehouse_action_ids.indexOf(data5.id) !== -1) {
                                                    report.shortcoming_actions.push({
                                                        id: data5.id,
                                                        category_id: data5.category_id,
                                                        subcategory_id: data5.subcategory_id,
                                                        action_name: data5.action_name,
                                                        active: true
                                                    });
                                                }
                                            }
                                        }
                                    });
                                }
                            }
                        });
                    }
                }
            });
        });
        return reportData;
    };
    ReportsBuildingsReportsListComponent.prototype.deleteBuildingReport = function (building_report_id) {
        var _this = this;
        this.reportsService.deleteBuildingReport(this.report_type, this.report_id, this.building_id, building_report_id).then(function (status) {
            if (status === true) {
                var index = _this.buildingReports.findIndex(function (data) { return data.id === building_report_id; });
                if (index !== -1) {
                    _this.buildingReports.splice(index, 1);
                }
            }
        });
    };
    ReportsBuildingsReportsListComponent.prototype.search = function ($event) {
        this.searchString = $event;
        this.currentPage = 0;
        this.getReportsBuildingReports(false);
    };
    ReportsBuildingsReportsListComponent.prototype.toggleBuildingReportInspectorStatus = function (status, report_type, report_id, building_id, building_report_id) {
        var _this = this;
        this.reportsService.verifyInspectorStatus(report_type, report_id, building_id, building_report_id, status).then(function (result) {
            if (typeof result !== 'undefined') {
                var index = _this.buildingReports.findIndex(function (data) { return data.id === building_report_id; });
                if (index !== -1) {
                    _this.buildingReports[index].verify_inspector_status = status;
                    _this.buildingReports[index].verify_inspector_date = moment().valueOf();
                }
            }
        });
    };
    ReportsBuildingsReportsListComponent.prototype.showBuildingMap = function () {
        this.reportsService.showReportBuildingMap(this.buildingMapUrl, true, this.mapMarkers).then(function () {
        });
    };
    return ReportsBuildingsReportsListComponent;
}());
export { ReportsBuildingsReportsListComponent };
