var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NativeDateAdapter } from '@angular/material/core';
import * as moment from 'moment';
var CustomDateAdapter = /** @class */ (function (_super) {
    __extends(CustomDateAdapter, _super);
    function CustomDateAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CustomDateAdapter.prototype.parse = function (value) {
        if ((typeof value === 'string') && (value.indexOf('/') > -1)) {
            var str = value.split('/');
            var year = parseInt('20' + Number(str[2]), 10);
            var month = Number(str[1]) - 1;
            var date = Number(str[0]);
            return new Date(year, month, date);
        }
        var timestamp = typeof value === 'number' ? value : Date.parse(value);
        return isNaN(timestamp) ? null : new Date(timestamp);
    };
    CustomDateAdapter.prototype.format = function (date, displayFormat) {
        if (displayFormat === 'input') {
            var day = date.getDate(), month = date.getMonth() + 1, year = date.getFullYear();
            return this._to2digit(day) + '-' + this._to2digit(month) + '-' + year;
        }
        else if (displayFormat === 'month-input') {
            var day = date.getDate(), year = date.getFullYear();
            return this._to2digit(day) + '. ' + moment(date).format('MMM') + ' ' + this._shortYear(year);
        }
        else {
            return date.toDateString();
        }
    };
    // noinspection JSMethodCanBeStatic
    CustomDateAdapter.prototype._to2digit = function (n) {
        return ('00' + n).slice(-2);
    };
    // noinspection JSMethodCanBeStatic
    CustomDateAdapter.prototype._shortYear = function (n) {
        return ('00' + n).slice(-2);
    };
    return CustomDateAdapter;
}(NativeDateAdapter));
export { CustomDateAdapter };
export var APP_DATE_FORMATS = {
    parse: {
        dateInput: {
            month: 'numeric',
            year: 'numeric',
            day: 'numeric'
        }
    },
    display: {
        dateInput: 'input',
        monthYearLabel: 'month-input',
        dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
        monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
    }
};
