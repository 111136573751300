var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { ClientsService } from '../../../../_services/clients.service';
import { ActivatedRoute } from '@angular/router';
import { GeneralService } from '../../../../_services/general.service';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { ApiService } from '../../../../_interceptors/api.service';
import { PageTitleService } from '../../../../_services/page-title.service';
import { environment } from '../../../../../environments/environment';
var ClientsContactPersonsListComponent = /** @class */ (function () {
    function ClientsContactPersonsListComponent(clientsService, generalService, previousUrlService, apiService, activatedRoute, pageTitleService) {
        this.clientsService = clientsService;
        this.generalService = generalService;
        this.previousUrlService = previousUrlService;
        this.apiService = apiService;
        this.activatedRoute = activatedRoute;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.client_id = null;
        this.isLoading = false;
        this.keepLoading = false;
        this.clientContactsPersons = [];
        this._activatedRouteSubscription = null;
        this.smallWidget = false;
    }
    ClientsContactPersonsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.clientContactsPersons.length === 0) {
                _this.currentPage = 0;
                _this.getClientContactsPersons(false);
            }
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.client_id = parseInt(params['client_id'], 10);
            if (_this.smallWidget === false) {
                _this.clientsService.getClient(_this.client_id).then(function (client) {
                    if (typeof client !== 'undefined') {
                        _this.pageTitleService.setTitle(client.company_name + ' | Bekijk contactpersonen | ' + environment.webappName);
                        _this.pageTitleService.setPageTitle(client.company_name);
                    }
                });
            }
            _this.previousUrlService.setPreviousUrlIfNull('/clients/' + _this.client_id + '/view');
            _this.getClientContactsPersons(false);
        });
    };
    ClientsContactPersonsListComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
    };
    ClientsContactPersonsListComponent.prototype.getClientContactsPersons = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.clientsService.getContactPersons(this.client_id, {
                start: (this.currentPage - 1) * (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
                length: (this.smallWidget ? this.generalService.itemsPerPageShort : this.generalService.itemsPerPage),
                'columns[0][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data.recordsTotal !== 'undefined') {
                    _this.totalItems = data.recordsTotal;
                }
                if (append_data === true) {
                    (_a = _this.clientContactsPersons).push.apply(_a, __spread(data.data));
                }
                else {
                    _this.clientContactsPersons = data.data;
                }
                _this.isLoading = false;
            });
        }
    };
    ClientsContactPersonsListComponent.prototype.deleteClientContactPerson = function (client_id, contact_person_id) {
        var _this = this;
        this.clientsService.deleteContactPerson(client_id, contact_person_id).then(function (status) {
            if (status === true) {
                var index = _this.clientContactsPersons.findIndex(function (data) { return data.id === contact_person_id; });
                if (index !== -1) {
                    _this.clientContactsPersons.splice(index, 1);
                }
            }
        });
    };
    ClientsContactPersonsListComponent.prototype.search = function ($event) {
        this.searchString = $event;
        this.currentPage = 0;
        this.getClientContactsPersons(false);
    };
    return ClientsContactPersonsListComponent;
}());
export { ClientsContactPersonsListComponent };
