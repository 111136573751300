import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FormsService } from '../../../../_services/forms.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../../_pipes/angular2-material-datepicker-custom-date';
import { PreviousUrlService } from '../../../../_services/previous-url.service';
import { InspectorsService } from '../../../../_services/inspectors.service';
import { ClientsService } from '../../../../_services/clients.service';
import { debounceTime, tap } from 'rxjs/operators';
import { ApiService } from '../../../../_interceptors/api.service';
var ɵ0 = APP_DATE_FORMATS;
var InspectorsInventoriesSendToClientComponent = /** @class */ (function () {
    function InspectorsInventoriesSendToClientComponent(inspectorsService, clientsService, formsService, activatedRoute, apiService, formBuilder, previousUrlService) {
        this.inspectorsService = inspectorsService;
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.apiService = apiService;
        this.formBuilder = formBuilder;
        this.previousUrlService = previousUrlService;
        this.routerTransition = true;
        this.inventory_id = null;
        this.client_id = null;
        this.isFilteredClient = false;
        this.isLoadingClient = false;
        this.dataLoaded = false;
        this.missingData = [];
        // noinspection JSMismatchedCollectionQueryUpdate
        this.clientsFiltered = null;
        this.inspectorInventory = null;
        // noinspection JSMismatchedCollectionQueryUpdate
        this.clientsContactsPersons = [];
        this.inspectoryInventorySendToClientForm = this.formBuilder.group({
            client_id: [''],
            client_filter_input: [''],
            contact_company: [true],
            contact_persons_ids: this.formBuilder.array([]),
            custom_contacts: [''],
            message: ['']
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    InspectorsInventoriesSendToClientComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.missingData.length > 0) {
                _this.missingData = [];
                _this.loadData();
            }
        });
        this.formsService.form = this.inspectoryInventorySendToClientForm;
        this._errorsSubscription = this.inspectorsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this.inspectoryInventorySendToClientForm
            .get('client_filter_input')
            .valueChanges
            .pipe(debounceTime(300), tap(function () { return _this.isLoadingClient = true; }))
            .subscribe(function (value) {
            if (typeof value === 'string') {
                if (value !== '' && value !== null) {
                    if (value.length >= 3) {
                        _this.clientsService.getClients({
                            start: 0,
                            length: -1,
                            'columns[0][search][value]': value
                        }).then(function (data) {
                            _this.clientsFiltered = data.data;
                            _this.isLoadingClient = false;
                            _this.isFilteredClient = true;
                        });
                    }
                    else {
                        _this.isLoadingClient = false;
                    }
                }
                else {
                    _this.isLoadingClient = false;
                }
            }
            else {
                _this.isLoadingClient = false;
            }
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.inventory_id = parseInt(params['inventory_id'], 10);
            _this.previousUrlService.setPreviousUrlIfNull('/inspectors/inventories');
            _this.loadData();
        });
    };
    InspectorsInventoriesSendToClientComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    };
    InspectorsInventoriesSendToClientComponent.prototype.loadData = function () {
        var _this = this;
        this.inspectorsService.getInspectorInventory(this.inventory_id).then(function (details) {
            if (typeof details !== 'undefined') {
                _this.inspectorInventory = details;
                _this.dataLoaded = true;
            }
            else {
                _this.missingData.push('Inventarie gegevens');
            }
        });
    };
    InspectorsInventoriesSendToClientComponent.prototype.getClientsContactsPersons = function (client) {
        var _this = this;
        this.client_id = client.id;
        var el = this.inspectoryInventorySendToClientForm.get('contact_company');
        if (client.email === '') {
            el.setValue(false);
            el.disable();
        }
        else {
            el.setValue(true);
            el.enable();
        }
        this.clientsService.getContactPersons(client.id).then(function (data) {
            if (typeof data !== 'undefined') {
                _this.clientsContactsPersons = data.data;
                if (data.data.length) {
                    data.data.forEach(function (contact_person) {
                        var control = _this.inspectoryInventorySendToClientForm.get('contact_persons_ids');
                        control.push(new FormControl({ value: contact_person.id, disabled: !contact_person.email }));
                    });
                }
            }
        });
    };
    InspectorsInventoriesSendToClientComponent.prototype.fixAngularScrewUpAndInsertActualValue = function (event, index) {
        if (event.checked) {
            this.inspectoryInventorySendToClientForm.controls.contact_persons_ids['controls'][index].setValue(event.source.value);
        }
    };
    return InspectorsInventoriesSendToClientComponent;
}());
export { InspectorsInventoriesSendToClientComponent };
export { ɵ0 };
