import { EventEmitter } from '@angular/core';
import { CustomErrorHandlerService } from './http.error-handler.service';
import { HttpClient } from '@angular/common/http';
import { LoadingOverlayService } from '../_services/loading-overlay.service';
import { environment } from '../../environments/environment';
import { SnackbarService } from '../_services/snackbar.service';
import { FileInput } from 'ngx-material-file-input';
import * as FileSaver from 'file-saver';
import { Subject } from 'rxjs/Rx';
var ApiService = /** @class */ (function () {
    function ApiService(http, errorHandler, snackbarService, loadingOverlayService) {
        var _this = this;
        this.http = http;
        this.errorHandler = errorHandler;
        this.snackbarService = snackbarService;
        this.loadingOverlayService = loadingOverlayService;
        this.offlineError = new EventEmitter(false);
        this.loginError = new EventEmitter(false);
        this.internalConnectionChanged = new Subject();
        window.addEventListener('online', function () {
            _this.updateOnlineStatus();
        });
        window.addEventListener('offline', function () {
            _this.updateOnlineStatus();
        });
    }
    Object.defineProperty(ApiService.prototype, "connectionChanged", {
        get: function () {
            return this.internalConnectionChanged.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ApiService.prototype, "isOnline", {
        get: function () {
            return !!window.navigator.onLine;
        },
        enumerable: true,
        configurable: true
    });
    ApiService.prototype.updateOnlineStatus = function () {
        this.internalConnectionChanged.next(window.navigator.onLine);
    };
    ApiService.prototype.get = function (url, urlData, showLoader) {
        var _this = this;
        if (showLoader === void 0) { showLoader = true; }
        this.showLoader(showLoader);
        return this.http.get(environment.api_endpoint + url + this.buildGetUrl(urlData))
            .map(function (res) { return _this.handleResponse(res, showLoader); })
            .toPromise()
            .catch(function (error) { return _this.handleErrorResponse(error, showLoader); });
    };
    ApiService.prototype.post = function (url, formData, showLoader) {
        var _this = this;
        if (showLoader === void 0) { showLoader = true; }
        this.showLoader(showLoader);
        return this.http.post(environment.api_endpoint + url, this.processFormData(formData))
            .map(function (res) { return _this.handleResponse(res, showLoader); })
            .toPromise()
            .catch(function (error) { return _this.handleErrorResponse(error, showLoader); });
    };
    ApiService.prototype.delete = function (url, showLoader) {
        var _this = this;
        if (showLoader === void 0) { showLoader = true; }
        this.showLoader(showLoader);
        return this.http.delete(environment.api_endpoint + url)
            .map(function (res) { return _this.handleResponse(res, showLoader); })
            .toPromise()
            .catch(function (error) { return _this.handleErrorResponse(error, showLoader); });
    };
    ApiService.prototype.put = function (url, formData, showLoader) {
        var _this = this;
        if (showLoader === void 0) { showLoader = true; }
        this.showLoader(showLoader);
        return this.http.put(environment.api_endpoint + url, this.processFormData(formData))
            .map(function (res) { return _this.handleResponse(res, showLoader); })
            .toPromise()
            .catch(function (error) { return _this.handleErrorResponse(error, showLoader); });
    };
    ApiService.prototype.download = function (url, filename, showLoader, mimeType) {
        var _this = this;
        if (filename === void 0) { filename = null; }
        if (showLoader === void 0) { showLoader = true; }
        if (mimeType === void 0) { mimeType = 'application/octet-stream'; }
        this.showLoader(showLoader);
        this.http.get(url, {
            observe: 'response',
            responseType: 'blob'
        })
            .subscribe(function (res) {
            var header = res.headers.get('content-disposition');
            FileSaver.saveAs(new Blob([res.body], { type: mimeType }), (filename !== null ? filename : (header !== null ? _this.getFileNameFromHttpResponse(header) : null)));
            if (showLoader) {
                _this.loadingOverlayService.hideLoader();
            }
        }, function (error) {
            _this.snackbarService.error('An error occurred while downloading file.');
            console.log(error);
            if (showLoader) {
                _this.loadingOverlayService.hideLoader();
            }
            throw new Error(error);
        });
    };
    ApiService.prototype.getFileNameFromHttpResponse = function (httpResponse) {
        var result = httpResponse.split(';')[1].trim().split('=')[1];
        return result.replace(/"/g, '');
    };
    ApiService.prototype.buildGetUrl = function (urlData) {
        var queryString = '?type=json';
        if (typeof urlData !== 'undefined') {
            if (urlData !== null) {
                for (var key in urlData) {
                    if (urlData.hasOwnProperty(key)) {
                        if (!queryString) {
                            queryString = '?' + key + '=' + urlData[key];
                        }
                        else {
                            queryString += '&' + key + '=' + urlData[key];
                        }
                    }
                }
            }
        }
        return queryString;
    };
    ApiService.prototype.processFormData = function (formData) {
        var postData = new FormData(), ignoreInputs = [
            '_filterString',
            'photosContainer[]',
        ];
        if (typeof formData !== 'undefined') {
            if (formData !== null) {
                Object.keys(formData).forEach(function (key) {
                    if (ignoreInputs.indexOf(key) === -1) {
                        if (formData[key] instanceof FileInput) {
                            for (var i = 0; i < formData[key]['_files'].length; i++) {
                                postData.append(key + (formData[key]['_files'].length > 1 ? '[]' : ''), formData[key]['_files'][i], formData[key]['_files'][i].name);
                            }
                        }
                        else if (Array.isArray(formData[key])) {
                            for (var i = 0; i < formData[key].length; i++) {
                                if (formData[key][i] !== false && formData[key][i] !== 'undefined') {
                                    postData.append(key + '[]', formData[key][i]);
                                }
                            }
                        }
                        else if (formData[key] instanceof Object) {
                            // do nothing
                            Object.keys(formData[key]).forEach(function (key2) {
                                if (formData[key][key2] !== false && formData[key][key2] !== 'undefined') {
                                    if (key2.indexOf('[') !== -1) {
                                        postData.append(key + key2, formData[key][key2]);
                                    }
                                    else {
                                        postData.append(key + '[' + key2 + ']', formData[key][key2]);
                                    }
                                }
                            });
                        }
                        else {
                            postData.append(key, formData[key]);
                        }
                    }
                });
                return postData;
            }
            else {
                return null;
            }
        }
        else {
            return null;
        }
    };
    ApiService.prototype.showLoader = function (showLoader) {
        if (showLoader) {
            this.loadingOverlayService.show();
        }
    };
    ApiService.prototype.handleResponse = function (res, showLoader) {
        if (showLoader) {
            this.loadingOverlayService.hide();
        }
        if (typeof res !== 'undefined') {
            if (typeof res.error !== 'undefined') {
                if (typeof res.message !== 'undefined') {
                    this.snackbarService.error(res.message);
                    return res;
                }
                else {
                    return res;
                }
            }
            else if (typeof res.success === 'undefined') {
                this.loadingOverlayService.showError();
                this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
                return { success: false };
            }
            else {
                return res;
            }
        }
        else {
            this.loadingOverlayService.showError();
            this.snackbarService.error('Er is een fout opgetreden tijdens het laden van de data, probeer het nogmaals.');
            return { success: false };
        }
    };
    ApiService.prototype.handleErrorResponse = function (error, showLoader) {
        this.loadingOverlayService.hide();
        if (error.status === 401) {
            this.loginError.next(true);
        }
        if (showLoader) {
            return Promise.resolve(this.errorHandler.tryParseError(error));
        }
        else {
            return Promise.resolve();
        }
    };
    ApiService.prototype.notAvailableOffline = function () {
        this.snackbarService.warning('Deze functie is alleen beschikbaar wanneer er een internet verbinding is.');
    };
    return ApiService;
}());
export { ApiService };
