var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { OnDestroy, OnInit } from '@angular/core';
import { GeneralService } from '../../../../_services/general.service';
import { InspectorsService } from '../../../../_services/inspectors.service';
import { ApiService } from '../../../../_interceptors/api.service';
var InspectorsInventoriesListComponent = /** @class */ (function () {
    function InspectorsInventoriesListComponent(inspectorsService, apiService, generalService) {
        this.inspectorsService = inspectorsService;
        this.apiService = apiService;
        this.generalService = generalService;
        this.routerTransition = true;
        this.totalItems = 0;
        this.currentPage = 0;
        this.searchString = '';
        this.isLoading = false;
        this.keepLoading = false;
        this.inspectorInventories = [];
    }
    InspectorsInventoriesListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.apiService.connectionChanged.subscribe(function (online) {
            if (online && _this.inspectorInventories.length === 0) {
                _this.currentPage = 0;
                _this.getInspectorsInventories();
            }
        });
        this.getInspectorsInventories(false);
    };
    InspectorsInventoriesListComponent.prototype.ngOnDestroy = function () {
        this.currentPage = 0;
        this.searchString = '';
    };
    InspectorsInventoriesListComponent.prototype.getInspectorsInventories = function (append_data) {
        var _this = this;
        if (append_data === void 0) { append_data = true; }
        if (!this.isLoading || this.keepLoading) {
            this.isLoading = true;
            this.keepLoading = false;
            this.currentPage++;
            this.inspectorsService.getInspectorsInventories({
                start: (this.currentPage - 1) * this.generalService.itemsPerPage,
                length: this.generalService.itemsPerPage,
                'columns[1][search][value]': this.searchString
            }).then(function (data) {
                var _a;
                if (typeof data !== 'undefined') {
                    if (typeof data.recordsTotal !== 'undefined') {
                        _this.totalItems = data.recordsTotal;
                    }
                    if (append_data === true) {
                        (_a = _this.inspectorInventories).push.apply(_a, __spread(data.data));
                    }
                    else {
                        _this.inspectorInventories = data.data;
                    }
                }
                _this.isLoading = false;
            });
        }
    };
    InspectorsInventoriesListComponent.prototype.deleteInspectorInventory = function (inventory_id) {
        var _this = this;
        this.inspectorsService.deleteInspectorInventory(inventory_id).then(function (status) {
            if (status === true) {
                var index = _this.inspectorInventories.findIndex(function (data) { return data.id === inventory_id; });
                if (index !== -1) {
                    _this.inspectorInventories.splice(index, 1);
                }
            }
        });
    };
    InspectorsInventoriesListComponent.prototype.search = function ($event) {
        this.searchString = $event;
        this.currentPage = 0;
        this.getInspectorsInventories(false);
    };
    return InspectorsInventoriesListComponent;
}());
export { InspectorsInventoriesListComponent };
