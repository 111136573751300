import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormsService } from '../../../_services/forms.service';
import { ClientsService } from '../../../_services/clients.service';
import { ActivatedRoute } from '@angular/router';
import { APP_DATE_FORMATS } from '../../../_pipes/angular2-material-datepicker-custom-date';
import * as moment from 'moment';
import { PreviousUrlService } from '../../../_services/previous-url.service';
import { PageTitleService } from '../../../_services/page-title.service';
import { environment } from '../../../../environments/environment';
var ɵ0 = APP_DATE_FORMATS;
var ClientChangeNextInspectionDateComponent = /** @class */ (function () {
    function ClientChangeNextInspectionDateComponent(clientsService, formsService, activatedRoute, previousUrlService, formBuilder, pageTitleService) {
        this.clientsService = clientsService;
        this.formsService = formsService;
        this.activatedRoute = activatedRoute;
        this.previousUrlService = previousUrlService;
        this.formBuilder = formBuilder;
        this.pageTitleService = pageTitleService;
        this.routerTransition = true;
        this.client_id = null;
        this.dataLoaded = false;
        this.isOffline = false;
        this.nextInspectionDetails = null;
        this.changeNextInspectionDateForm = this.formBuilder.group({
            next_inspection_date: ['', Validators.required],
            next_inspection_date_input: ['', Validators.required]
        });
        this._errorsSubscription = null;
        this._activatedRouteSubscription = null;
    }
    ClientChangeNextInspectionDateComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.formsService.form = this.changeNextInspectionDateForm;
        this._errorsSubscription = this.clientsService.errors.subscribe(function (errors) {
            _this.formsService.processErrors(errors);
        });
        this._activatedRouteSubscription = this.activatedRoute.params.subscribe(function (params) {
            _this.client_id = parseInt(params['client_id'], 10);
            _this.clientsService.getClient(_this.client_id).then(function (client) {
                if (typeof client !== 'undefined') {
                    _this.pageTitleService.setTitle(client.company_name + ' | Aanpassen aankomende inspectie datum | ' + environment.webappName);
                    _this.pageTitleService.setPageTitle(client.company_name);
                }
            });
            _this.previousUrlService.setPreviousUrlIfNull('/clients/' + _this.client_id + '/view');
            _this.clientsService.getChangeNextInspectionDate(_this.client_id).then(function (nextInspectionDetails) {
                if (typeof nextInspectionDetails !== 'undefined') {
                    _this.changeNextInspectionDateForm.get('next_inspection_date').setValue(new Date(nextInspectionDetails.next_inspection_date));
                    _this.nextInspectionDetails = nextInspectionDetails;
                    _this.dataLoaded = true;
                }
                else {
                    _this.isOffline = true;
                }
            });
        });
    };
    ClientChangeNextInspectionDateComponent.prototype.ngOnDestroy = function () {
        if (this._activatedRouteSubscription !== null) {
            this._activatedRouteSubscription.unsubscribe();
        }
        if (this._errorsSubscription !== null) {
            this._errorsSubscription.unsubscribe();
        }
    };
    ClientChangeNextInspectionDateComponent.prototype.updateNextInspectionDate = function ($event) {
        this.changeNextInspectionDateForm.get('next_inspection_date').setValue(moment($event.value).format('DD-MM-YYYY'));
    };
    return ClientChangeNextInspectionDateComponent;
}());
export { ClientChangeNextInspectionDateComponent };
export { ɵ0 };
